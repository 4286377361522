import React from "react";
import { useEffect, useRef, useState } from "react";
import { _t } from "../i18n";
interface Props {
  children: any;
}

export const getGoogleAuthorizedUrl = async () => {
  const res = await fetch("/private-api/get-google-authorized-url", {
    method: "POST",
  });
  return res.json();
};

export function GoogleButton(props: Props) {
  const login = (ev) => {
    ev.preventDefault();
    getGoogleAuthorizedUrl().then((data) => {
      const { url } = data;
      document.location.href = url;
    });
  };
  return <button onClick={login}>{props.children}</button>;
}

export default {};
