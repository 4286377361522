import React, { useEffect, FormEvent, useRef, useState } from "react";
import { _t } from "../../i18n";
import * as ls from "../../local-storage.ts";

interface LandingPageProps {
  setShowLandingPage: (x: boolean) => void;
  activeUserNumber: null | number;
}

export default (props: LandingPageProps) => {
  const { setShowLandingPage, activeUserNumber } = props;

  return (
    <article className="landing-page">
      <h2 id="landing-page-h2">{_t("landing-page.heading")}</h2>

      <ol id="landing-page-ol">
        <li>{_t("landing-page.step1")}</li>
        <li>{_t("landing-page.step2")}</li>
        <li>{_t("landing-page.step3")}</li>
        <li>{_t("landing-page.step4")}</li>
      </ol>

      {activeUserNumber && (
        <div className="landing-page-check-line form-line">
          <input
            id="do-not-show-again"
            type="checkbox"
            onClick={() =>
              ls.set(
                "do-no-show-landing-page-" + String(activeUserNumber),
                true
              )
            }
          />
          <label forhtml="do-not-show-again">
            {_t("landing-page.do-not-show-this-again")}
          </label>{" "}
        </div>
      )}
      <button
        id="landing-page-close-button"
        onClick={() => setShowLandingPage(false)}
      >
        Cerrar
      </button>

      <span
        id="landing-page-close-button-corner"
        className="fa fa-times"
        onClick={() => setShowLandingPage(false)}
      />
    </article>
  );
};
