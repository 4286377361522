import { _t } from "./i18n";

export function critiquePassword(text: string) {
  // make this more robust later...
  if (text.length < 3) {
    return _t("register.should-be-3-long");
  }
  return "";
}

const toExport = { critiquePassword };
// @ts-ignore
export default toExport;
