import * as React from "react";
import { useState, useCallback, useEffect, useRef } from "react";
import L from "leaflet";
import { shrinkPhoto } from "../api";
// @gus-include problem.pot-hole
// @gus-include problem.damaged sign
// @gus-include problem.missing sign

import { Buffer } from "buffer";

import {
  Comodoro_Rivadavia,
  decimalToSexigesimal,
  focusZoom,
  isBoundingBoxStringQuad,
  isPlace,
  maxZoom,
  parseBoundingBoxStringQuad,
  Place,
  wideZoom,
} from "../osm";

import * as ls from "../local-storage";

import {
  Config,
  Problem,
  searchProblems,
  getProblemImages,
  OptionalProblem,
} from "../api";
import { isNumber, isStandardException, sleep, ViewType } from "../sdptypes";
import { SubmitProject } from "./submitproject";
import { _t } from "../i18n";

type Timeout = ReturnType<typeof setTimeout>;
type LeafLetType = any;
const defaultZoom = 13;

interface ProblemListRowProps extends Problem {
  allProblemPhotosLoaded: boolean;
  activeUserBitcoincashAddress: null | string;
  changeList: Array<OptionalProblem>;
  currentCity: string;
  defaultImage: string;
  defaultPhotoEncoding: string;
  deleteProblem: (id: number) => void;
  description: string;
  fetchingImages: boolean;
  fetchTimeout: number; //ms
  openProblem: (id: number) => void;
  problemId: number;
  roles: Array<string>;
  selected: boolean;
  sessionId: string;
  setErrorMessage: (msg: string) => void;
  setProblemRow: (p: OptionalProblem) => void;
  setSelectedProblemId: (id: number) => void;
  setShowProjectSubmissionForm: (p: boolean) => void;
  setZoom: (n: number) => void;
  showProjectSubmissionForm: boolean;
  zoom: number;
}

export function ProblemListRow(x: ProblemListRowProps) {
  const {
    allProblemPhotosLoaded,
    activeUserBitcoincashAddress,
    currentCity,
    defaultImage,
    defaultPhotoEncoding,
    fetchingImages,
    fetchTimeout,
    id,
    sessionId,
    setErrorMessage,
    roles,
    setProblemRow,
    deleteProblem,
    selected,
    setSelectedProblemId,
    setShowProjectSubmissionForm,
    setZoom,
    image,
    changeList,
    zoom,
  } = x;

  const [changes, setChanges] = useState<OptionalProblem>({ id });
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formProblemType, setFormProblemType] = useState(x.problemType);
  const [upgradingImage, setUpgradingImage] = useState(false);
  const [downloadSpeed, setDownloadSpeed] = useState<null | number>(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const listImageRef = useRef(null);

  const EW = `E${_t("problem.west-letter")}`;
  const NS = "NS";

  //if (image) console.log("image:", image.slice(0, 44) + "... ");
  useEffect(() => {
    const thisChangeList = changeList.filter((change) => change.id === id);
    let changeSet: OptionalProblem = { id };
    for (const change of thisChangeList) {
      changeSet = { ...changeSet, ...change };
    }
    setChanges(changeSet);
    setEditMode(Object.keys(changeSet).length > 1);
  }, [changeList]);

  const handleAddressChange = (problemId, event) => {
    try {
      console.log(
        `handling change of address: New value is ${event.target.value}`,
      );
      const newAddress = event.target.value;
      let streetName: string;
      let streetNumber: number;
      let city: string | undefined;
      let changeSet: OptionalProblem = { id: problemId };
      let search =
        newAddress.match(
          /^(?<streetName>\w([\w\s]+)\w) +(?<streetNumber>\d+) *(,(?<city>\w[^,]+))?$/,
        ) ||
        newAddress.match(
          /^(?<streetNumber>\d+) +(?<streetName>\w([\w\s]+)\w) *(,(?<city>\w[^, ]+))?$/,
        ) ||
        newAddress.match(/^(?<streetName>\w[\w\s]+)$/);
      console.log(search?.groups);
      if (search) {
        streetName = search.groups.streetName;
        streetNumber = parseInt(search.groups.streetNumber);
        city = search.city;

        if (streetName && streetName !== x.streetName) {
          changeSet["streetName"] = streetName;
        }

        if (streetNumber && x.streetNumber !== streetNumber) {
          changeSet["streetNumber"] = streetNumber;
        }

        if (city && x.city !== city) {
          changeSet["city"] = city;
        }

        console.log(changeSet);
      } else {
        console.log(`Couldn't parse address: '${newAddress}'`);
        return;
      }

      setChanges(changeSet);
    } catch (e) {
      console.error(e);
      // @ts-ignore
      setErrorMessage(e.message);
    }
  };

  const handleSaveProblem = () => {
    if (saving) {
      return;
    }
    setSaving(true);
    const headers: Array<[string, string]> = [["session_id", sessionId]];
    //console.log(changes);
    for (const key in changes) {
      if (typeof changes[key] === "object" && key === "location") {
        headers.push([
          "location",
          changes[key]?.decLatitude + ", " + changes[key]?.decLongitude,
        ]);
      } else {
        headers.push([key, changes[key]]);
      }
    }

    console.log(headers);
    Promise.any([
      fetch("/private-api/editProblem", {
        headers,
      }),
      sleep(fetchTimeout),
    ])
      .then(async (resp) => {
        if (!resp) {
          throw new Error(_t("g.timeout"));
        }
        const { status } = resp;
        if (status === 404) {
          setErrorMessage("Not yet implemented.");
        } else if (status === 200) {
          resp.json().then((editProblemJSON) => {
            console.log(editProblemJSON);
            setProblemRow({ ...changes });
            setChanges({ id });
          });
        }
      })
      .catch(console.error)
      .finally(() => {
        setSaving(false);
        setEditMode(false);
      });
  };

  const handleDeleteProblem = async () => {
    try {
      const headers: Array<[string, string]> = [
        ["session_id", sessionId],
        ["id", x.id + ""],
      ];

      const deleteResponse = await Promise.any([
        fetch("/private-api/deleteProblem", {
          headers,
        }),
        sleep(fetchTimeout),
      ]);
      if (!deleteResponse) {
        setErrorMessage(_t("g.timeout"));
        return;
      }
      const deleteJSON = await deleteResponse.json();
      const { success, errorMessage } = deleteJSON;
      if (success) {
        try {
          deleteProblem(x.id);
        } catch (e) {
          console.error(e);
          // remake the map?
        }
      } else {
        setErrorMessage(errorMessage);
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      //
    }
  };

  const address = `${x.streetName}${
    x.streetNumber ? x.streetNumber : ""
  }${x.crossStreetName ? "@ " + x.crossStreetName : ""}${
    x.city && x.city !== currentCity ? ", " + x.city : ""
  }`;

  const listImage = listImageRef.current;

  useEffect(() => {
    // do not upgrade any until we have them all.
    if (upgradingImage && allImagesLoaded && !allProblemPhotosLoaded) return () => {};

    setUpgradingImage(true);
    //console.log("checking images");
    try {
      const img = listImage as HTMLImageElement | null;
      if (img) {
        //const style = getComputedStyle( img );
        if (img.naturalHeight < img.height || img.naturalWidth < img.width) {
          //console.log(
          //  `Image dimensions in the page are ${img.width}x${img.height} but the pixels are only ${img.naturalWidth}x${img.naturalHeight}... getting new.`,
          //);
          getProblemImages(
            [id],
            img.naturalHeight * 4 <= 3 * img.naturalWidth
              ? img.width
              : (4 * img.height) / 3,
          )
            .then((betterImageSet) => {
              if (betterImageSet.length) {
                //console.log("upgrading image ", betterImageSet);
                setProblemRow({ id, image: betterImageSet[0].image });
              }
              setUpgradingImage(false);
            })
            .catch((e) => {
              console.error(e);
              setUpgradingImage(false);
            });
        } else {
          console.log(`img is sufficient here`);
          setUpgradingImage(false);
        }
      } else {
        //console.log("img is undefined or null", img);
        setUpgradingImage(false);
      }
    } catch (e) {
      setUpgradingImage(false);
    }
  }, [id, listImage, setUpgradingImage, allProblemPhotosLoaded]);

  if (saving)
    return (
      <tr>
        <td colSpan={(roles.includes("admin") ? 1 : 0) + 6}>
          {_t("g.saving")}
        </td>
      </tr>
    );

  const toggleSelect = (ev) => x.openProblem(x.id);

  const handleModification = (field: string, ev) => {
    console.log("handleModification called", field, ev.target.value);
    const newChanges = { ...changes, [field]: ev.target.value };
    setChanges(newChanges);
  };

  const handleProblemTypeChange = (ev) => {
    handleModification("problem-type", ev);
    setFormProblemType(ev.target.value);
  };

  const title = x.title
    .replaceAll("problems.missing sign", _t("problem.missing sign"))
    .replaceAll("problems.damaged sign", _t("problem.damaged sign"))
    .replaceAll("problems.pot hole", _t("problem.pot hole"));

  return (
    <tr
      key={x.id}
      data-problem-id={x.id}
      data-problemid={x.id}
      className={selected ? "selectedRow" : "unSelectedRow"}
    >
      {/* If the structure here is modified scrollToSelectedProblem, and scrollToProblem may need to be modified or rewritten
         so that scrolling to a problem will work. */}
      {x.roles.includes("admin") && (
        <td>
          <span
            onClick={handleDeleteProblem}
            className="fa fa-trash wallbreathe"
          />
        </td>
      )}

      {
        <td>
          <div className="image-over-text-container">
            <div
              className="text-over-image"
              onClick={editMode ? () => 0 : toggleSelect}
            >
              {!x.image && fetchingImages ? (
                <div className="fa fa-photo" />
              ) : x.image &&
                x.image !== `${defaultPhotoEncoding},${defaultImage}` ? (
                <img
                  className="listImage"
                  alt="problem"
                  src={x.image}
                  ref={listImageRef}
                />
              ) : (
                <div className="fa fa-photo" />
              )}
            </div>
            {editMode ? (
              <input
                type="text"
                onChange={(ev) => handleModification("title", ev)}
                disabled={!x.roles.includes("admin")}
                defaultValue={x.title}
              />
            ) : (
              <div className="text-under-image">{title}</div>
            )}
          </div>
        </td>
      }
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {editMode ? (
          <select onChange={handleProblemTypeChange}>
            <option
              value="pot hole"
              selected={formProblemType === "pot hole" || undefined}
            >
              {_t("problem.pot hole")}
            </option>
            <option
              value="damaged sign"
              selected={formProblemType === "damaged sign" || undefined}
            >
              {_t("problem.damaged sign")}
            </option>
            <option
              value="missing sign"
              selected={formProblemType === "missing sign" || undefined}
            >
              {_t("problem.missing sign")}
            </option>
          </select>
        ) : (
          _t("problem." + formProblemType)
        )}
      </td>
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {editMode ? (
          <input
            type="text"
            disabled={!x.roles.includes("admin")}
            defaultValue={address}
            onChange={(ev) => handleAddressChange(x.id, ev)}
          />
        ) : (
          <span>{address}</span>
        )}
      </td>
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {x?.location?.decLatitude &&
          (editMode ? (
            <input
              type="text"
              defaultValue={
                (x?.location?.decLatitude &&
                  decimalToSexigesimal(x.location.decLatitude, NS)) +
                " " +
                (x?.location?.decLongitude &&
                  decimalToSexigesimal(x.location.decLongitude, EW))
              }
            />
          ) : (
            <span>
              {decimalToSexigesimal(x?.location?.decLatitude, NS) +
                " " +
                decimalToSexigesimal(x?.location?.decLongitude, EW)}
            </span>
          ))}
      </td>
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {editMode ? (
          <textarea
            defaultValue={x.body ?? ""}
            onChange={(ev) => handleModification("body", ev)}
          />
        ) : (
          <span>{x.body}</span>
        )}
      </td>
      {x.roles.includes("provider") &&
        activeUserBitcoincashAddress &&
        activeUserBitcoincashAddress > "" && (
          <td>
            <span
              className="fa fa-plus"
              onClick={() => {
                setSelectedProblemId(x.id);
                setShowProjectSubmissionForm(true);
              }}
            >
              {_t("problem.new-project")}
            </span>
          </td>
        )}
      {x.roles.includes("admin") && (
        <td className="wide">
          {editMode || (
            <span onClick={() => setEditMode(true)} className="fa fa-pencil" />
          )}
          {editMode && (
            <span onClick={() => setEditMode(false)} className="fa fa-times" />
          )}
          {editMode && Object.keys(changes).length > 0 && (
            <span onClick={handleSaveProblem} className="fa fa-save" />
          )}
        </td>
      )}
    </tr>
  );
}

interface ProblemMapProps {
  title: string;
  place: Place;
  cityDecimalLatitude: string;
  cityDecimalLongitude: string;
  problemSet: Array<Problem>;
  mapId?: string;
  selectedProblemId?: number;
  setProblemSet: (ps: Array<Problem>) => void;
  setSelectedProblemId?: (id: number) => void;
  setZoom: (z: number) => void;
  scrollToProblem?: (id: number) => void;
  style?: { [id: string]: string | number };
  zoom: null | number;
}

function ProblemMap(props: ProblemMapProps) {
  const { place, problemSet, mapId, setZoom, zoom } = props;
  const { selectedProblemId } = props;
  const [controlId, setControlId] = useState<undefined | string>(mapId);
  const [settingMap, setSettingMap] = useState<boolean>(false);
  const [mapIsSet, setMapIsSet] = useState<boolean>(false);
  const [boundingBox, setBoundingBox] = useState<
    null | { lat: number; lng: number; alt: number }[]
  >(null);
  const [centerCoordiantes, setCenterCoordinates] = useState<null | string[]>(
    null,
  );
  // normally zooms are done in the map and the map control controls the
  // zooom value (save the initial setup).  Here set zoomChangeCount to
  // an incrementing function to force the map to rerender
  const [zoomChangeCount, setZoomChangeCount] = useState(0);
  const style = props.style;
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    // Set the id in the map div.
    // Because I want this called only once, the routine renders
    // once and then this is called, setting the controlId.

    // console.log("setting id");
    console.log("controlId is " + controlId);

    if (controlId && controlId > "")
      return () => {
        // Without this, it would try to undo itself and then generate a new string for an id -- adnauseum.
        // return early.  this is a redundant call.
      };

    let newId = "";
    for (let i = 0; i < 12; ++i) {
      newId += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[Math.floor(Math.random() * 26)];
    }
    setControlId(newId);

    return () => {
      // There is nothing really to be gained setting this to null here.
      // A new random string is not going to be better than the old one.
      // console.error("calling the id destructor now.");
    };
  }, []);

  useEffect(() => {
    // Run after the second render, for it needs to have the map rendered
    // with the id assigned in an earlier effect.  This only happens once
    // though.
    // console.log("Running the L.map() constructor");

    if (!controlId) return () => {};

    if (mapRef.current || settingMap || mapIsSet) {
      return () => {};
    }

    setSettingMap(true);
    let mapObj = L.map(controlId);
    mapRef.current = mapObj;
    setSettingMap(false);
    setMapIsSet(true);
  }, [controlId]);

  const mapObj = mapRef.current;

  useEffect(() => {
    if (!mapObj) return () => {};

    const zoomendHandler = (ev: L.ZoomAnimEvent) => {
      console.log("capturing zoom end", ev);
      const { sourceTarget } = ev;
      if (sourceTarget && (sourceTarget._zoom || sourceTarget._zoom === 0)) {
        console.log("Zoom detected.  Setting zoom to:", sourceTarget._zoom);
        setZoom(sourceTarget._zoom);
      }
    };
    mapObj.on("zoomend", zoomendHandler);
    return () => mapObj.off("zoomend", zoomendHandler);
  }, [mapObj]);

  useEffect(() => {
    if (!mapObj) {
      return () => {};
    }

    if (selectedProblemId === null && centerCoordiantes) {
      console.log("setting zoom to " + zoom !== null ? zoom : defaultZoom);
      mapObj.setView(centerCoordiantes, zoom !== null ? zoom : defaultZoom);
    } else if (selectedProblemId === null && boundingBox) {
      setBoundingBox(boundingBox);
      console.log("setting zoom to " + zoom !== null ? zoom : defaultZoom);
      mapObj.setZoom(zoom ? zoom : defaultZoom);
    } else if (
      selectedProblemId === null &&
      isBoundingBoxStringQuad(place.boundingbox)
    ) {
      const parsedBoundingBox = parseBoundingBoxStringQuad(place.boundingbox);
      setBoundingBox(parsedBoundingBox);
      mapObj.fitBounds(parsedBoundingBox);
      mapObj.setZoom(zoom !== null ? zoom : defaultZoom);
      console.log("setting zoom to " + zoom !== null ? zoom : defaultZoom);
    } else {
      setCenterCoordinates([
        place.lat, // N or S
        place.lon, // E or W
      ]);
      mapObj.setView(
        [
          place.lat, // N or S
          place.lon, // E or W
        ],
        zoom === null ? defaultZoom : zoom,
      );
    }
  }, [mapIsSet, mapObj, selectedProblemId, zoomChangeCount]);

  useEffect(() => {
    if (mapObj) {
      const tileLayer = L.tileLayer(
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom,
          attributionControl: false,
        },
      ).addTo(mapObj);
      return () => {
        tileLayer.removeFrom(mapObj);
      };
    } else {
      return () => {};
    }
  }, [mapIsSet, mapObj, zoomChangeCount]);

  useEffect(() => {
    if (mapObj && mapObj.attributionControl?.setPrefix)
      mapObj.attributionControl.setPrefix("Leaflet & OpenStreetMap");
  }, [mapObj]);

  const map = (mapObj as L.Map) || null;

  // @renderInMapEffect: Will update marker settings on every change to
  // problemSet.
  useEffect(() => {
    // If the problemSet changes, this effect runs the placeMarkers routine.
    // If already run once, it will run the placeMarker destructor
    // prior to doing that.
    try {
      const placeMarkers = (mapObj) => {
        if (!controlId || !mapIsSet) return () => {};

        for (const problem of problemSet) {
          const { marker, image, streetName, streetNumber, crossStreetName } =
            problem;
          const title = streetName
            ? streetNumber
              ? _t("problem.number-street-address", {
                  streetNumber,
                  streetName,
                })
              : crossStreetName
                ? _t("problem.cross-street-address", {
                    crossStreetName,
                    streetName,
                  })
                : streetName
            : "";

          if (image && marker) {
            try {
              const i = document.createElement("img");
              i.onload = () => {
                const s = document.createElement("span");
                const d = document.createElement("div");
                i.className = "listImage";
                s.innerText = title;

                d.appendChild(s);
                // if (i.naturalHeight === 1) {
                //   // Image too small
                // }
                i.className = "popup";
                d.className = "verticallyArranged";
                d.appendChild(i);
                marker.bindTooltip(d);
              };
              i.src = image;
              marker.on("dragend", console.log);
            } catch (e) {
              console.error(e, { image, problem });
            }
          }

          if (marker) {
            mapObj.addLayer(marker);
          }
        } // for

        return () => {
          // console.log("place marker destructor.  Remove markers here?");
          const newProblemSet = [...problemSet];
          for (const problem of newProblemSet) {
            const marker = problem.marker;
            if (marker) {
              marker.off("dragend", console.log);
              marker.removeFrom(mapObj);
            }
          }
        };
      };
      if (mapObj) return placeMarkers(mapObj);
    } catch (e) {}
    return () => {};
  }, [mapIsSet, mapObj, problemSet, zoomChangeCount]);

  const problem = problemSet.find((p) => p.id === selectedProblemId);
  useEffect(() => {
    const dtor = () => {};
    if (!map) {
      return dtor;
    }

    if (!problem) {
      document.title = props.title;
      return dtor;
    }

    const { location, marker } = problem;
    const { decLatitude: latitude, decLongitude: longitude } = location;
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error("latitude and longitude wrong:", problem);
      return dtor;
    }
    map.setView([latitude, longitude], zoom ?? focusZoom);
    if (!marker) return dtor;
    //console.log("Marker present...");
    marker.openTooltip([latitude, longitude]);
    return () => {
      marker.closeTooltip();
    };
  }, [mapIsSet, mapObj, problem, zoomChangeCount]);

  return (
    controlId && (
      <div>
        <div style={style} id={controlId} className="problemMap">
          <div
            id="zoom-in-button"
            className={zoom === focusZoom ? "zoom-disabled" : "zoom-enabled"}
            onClick={(ev) => {
              setZoom(focusZoom);
              setZoomChangeCount((x) => x + 1);
            }}
          >
            ++
          </div>
        </div>
      </div>
    )
  );
}

interface Project {
  author: number;
  provider: number;
  validators: Array<number>;
  body: string;
  goal: string;
  language: string;
  latitude: string;
  longitude: string;
  streetName: string;
  streetNumber: string;
  title: string;
  problemType: string; // 'pot hole', 'missing sign', 'damaged sign'
}
interface ProblemProps extends Problem {}

export function ProblemControl(props: ProblemProps) {
  const { streetName, streetNumber, problemType, body } = props;
  return (
    <tr>
      <td>{problemType}</td>
      <td>
        {streetNumber} {streetName}
      </td>
      <td>{body}</td>
    </tr>
  );
}

interface ProblemListProps {
  activeUserEthereumAddress: string;
  activeUserBitcoincashAddress: null | string;
  activeUserNumber: number;
  cities: { [id: string]: Place };
  config: Config;
  currentCity: string;
  defaultPhotoEncoding: string;
  defaultImage: string;
  deleteProblem: any;
  fetchTimeout: number; //ms
  language: string;
  loadedProblems: boolean;
  oSMEnabled: boolean;
  problemSet: Array<Problem>;
  roles: Array<string>;
  selectedProblemId: number;
  sessionId: string;
  setActiveUserEthereumAddress: (a: string) => void;
  setErrorMessage: (m: string) => void;
  setLoadedProblems: (b: boolean) => void;
  setProblemSet: (a: Array<Problem>) => void;
  setSelectedProblemId: (n: number | null) => void;
  setProblemListRef: (ref: { current: any }) => void;
  setProblemTableHeaderRef: (ref: { current: any }) => void;
  setProblemTableBodyRef: (ref: { current: any }) => void;
  setProblemTableRef: (ref: { current: any }) => void;
  setShowProjectSubmissionForm: (b: boolean) => void;
  setZoom: (n: number) => void;
  showProjectSubmissionForm: boolean;
  style?: { [id: string]: any };
  view: ViewType;
  websiteName: string;
  zoom: null | number;
}

export function ProblemList(props: ProblemListProps): JSX.Element {
  const {
    activeUserBitcoincashAddress,
    activeUserEthereumAddress,
    activeUserNumber,
    cities,
    config,
    currentCity,
    defaultImage,
    defaultPhotoEncoding,
    deleteProblem,
    fetchTimeout,
    language,
    loadedProblems,
    problemSet,
    roles,
    selectedProblemId,
    sessionId,
    setErrorMessage,
    setLoadedProblems,
    setProblemSet,
    setSelectedProblemId,
    setShowProjectSubmissionForm,
    setZoom,
    showProjectSubmissionForm,
    websiteName,
    zoom,
  } = props;
  const [loadingProblems, setLoadingProblems] = useState(false);
  const [cityInfo, setCityInfo] = useState<Place>(
    cities[currentCity] ?? Comodoro_Rivadavia,
  );
  const [citySearch, onlySetCitySearch] = useState(currentCity);
  const [allProblemPhotosLoaded, setAllProblemPhotosLoaded] = useState(false);
  const setCitySearch = (pCityName: string) => {
    // This is a hard coded array, so it must be either undefined or perfect.
    if (!Object.keys(cities).includes(pCityName)) {
      if (cityTypeTimeout.current) clearTimeout(cityTypeTimeout.current);
      cityTypeTimeout.current = setTimeout(() => getNewCity(pCityName), 2000);
      return;
    }
    const maybeCityRecord: undefined | Place = cities[pCityName];
    if (maybeCityRecord && isPlace(maybeCityRecord)) {
      const cityRecord: Place = maybeCityRecord as Place;
      // cityRecord,boundingBox is a BoundsStringArray.
      ls.set("city", pCityName);
      if (isBoundingBoxStringQuad(cityRecord.boundingbox)) {
        onlySetCitySearch(pCityName);
        setCityInfo(cityRecord);
      } else {
        console.log("city info record is bad:", cityRecord);
        setCityInfo(Comodoro_Rivadavia);
      }
    } else {
      setCityInfo(Comodoro_Rivadavia);
    }
  };

  const [problemsType, onlySetProblemsType] = useState<
    "any" | "damaged sign" | "missing sign"
  >("any");

  const [needsToLoadImages, onlySetNeedsToLoadImages] = useState(0);
  const setNeedsToLoadImages = () => {
    // console.log(`called: needsToLoadImages`);
    onlySetNeedsToLoadImages((k) => k + 1);
  };
  const [fetchingImages, setFetchingImages] = useState(false);
  const [changeList, setChangeList] = useState<any>([]);

  const cityTypeTimeout = useRef<Timeout | null>(null);
  const mapLeafletRef = useRef<LeafLetType>(null);
  const problemTableHeaderRef = useRef<HTMLTableRowElement>(null);
  const problemTableBodyRef = useRef<HTMLTableSectionElement>(null);
  const problemTableRef = useRef<HTMLTableElement>(null);
  const problemListRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<{ [id: string]: L.Icon }>({});

  useEffect(() => {
    if (!loadedProblems) {
      console.log("Loading problems due to a use Effect call");
      loadProblems(problemsType).then((failureString) => {
        setAllProblemPhotosLoaded(false);
        setLoadedProblems(failureString === "");
        console.log(failureString);
      });
      setNeedsToLoadImages();
    }
  }, [config]);

  const scrollToProblem = (id) => {
    if (
      !problemListRef.current ||
      !problemTableRef.current ||
      !problemTableHeaderRef.current ||
      !problemTableBodyRef.current ||
      !problemTableBodyRef?.current?.firstChild
    )
      return;
    console.log({ problemTable: problemTableRef.current });
    let scrollAmount = problemTableHeaderRef.current.clientHeight;
    console.log(scrollAmount);

    for (
      let tableRowElement: ChildNode | null | undefined =
        problemTableBodyRef?.current?.firstChild;
      !!tableRowElement;
      tableRowElement = tableRowElement.nextSibling
    ) {
      const thisProblemId: string | undefined =
        // @ts-ignore
        tableRowElement?.dataset?.problemid;
      if (!thisProblemId) break;

      if (parseInt(thisProblemId) === id) {
        console.log("returning...scrolling to ", scrollAmount);
        problemListRef.current.scrollTo({
          top: scrollAmount,
          behavior: "smooth",
        });

        return;
      }
      // @ts-ignore
      const clientHeight = tableRowElement?.clientHeight;
      if (!clientHeight || !isNumber(clientHeight)) break;
      scrollAmount += clientHeight;
    }
    console.error(
      "Failure to scroll due to an inability to find the problemId.",
      id,
    );
  };

  async function getNewCity(cityNameP: string): Promise<any> {
    if (!cityNameP)
      return new Promise<any>((result, error) => {
        result([]);
      });
    if (!Object.keys(cities).includes(cityNameP))
      new Promise<any>((result, error) => {
        result(cities[cityNameP]);
      });

    return Promise.any([
      fetch(
        `https://nominatim.openstreetmap.org/search?` +
          `format=json&q=${cityNameP}, Chubut, Argentina`,
      ),
      sleep(fetchTimeout),
    ])
      .then((rawResponse) => {
        if (!rawResponse) {
          setErrorMessage(_t("g.timeout"));
          return [];
        }
        if (rawResponse.ok) {
          rawResponse.json().then((response) => {
            console.log(cityNameP, response);
            const thisCity = response.find((x) =>
              ["city", "town", "village"].includes(x.addresstype),
            );
            if (thisCity) {
              onlySetCitySearch(thisCity.name);
              cities[cityNameP] = thisCity;
              setCityInfo(thisCity);
            }
          });
        }
      })
      .catch(console.error);
  }

  useEffect(() => {
    if (!mapLeafletRef.current) return () => {};
    const handleSelectedProblemIdChange = () => {
      if (selectedProblemId === null) {
        try {
          if (!cityInfo) {
            throw new Error("city Info not set");
          }
          const boundingBox: string[] = cityInfo.boundingbox;

          if (!isBoundingBoxStringQuad(boundingBox)) {
            console.log({ boundingBox, cityInfo });
            throw new Error("Not valid format for city Info");
          }
          const parsedBoundingBox = parseBoundingBoxStringQuad(boundingBox);
          mapLeafletRef.current.fitBounds(parsedBoundingBox);
        } catch (e) {
          // @ts-ignore
          if (!isStandardException(e) || e.message !== "city Info not set") {
            console.error(e);
          }
          mapLeafletRef.current.setView(
            [
              cityInfo?.lat ?? 0, // decimal - not S
              cityInfo?.lon ?? 0, // decimal - not W
            ],
            zoom ?? wideZoom,
          );
          console.error("using setView");
        }
      } else {
        const problem = problemSet.find((p) => p.id === selectedProblemId);
        if (problem && mapLeafletRef.current) {
          const { location } = problem;
          if (location) {
            const { decLatitude, decLongitude } = location;
            if (!isNaN(decLatitude) && !isNaN(decLongitude)) {
              mapLeafletRef.current.setView(
                [decLatitude, decLongitude],
                focusZoom,
              );
            }
          }
        }
      }
    };
    handleSelectedProblemIdChange();
    return () => {};
  }, [selectedProblemId, cityInfo, problemSet, zoom]);

  useEffect(() => {
    const potholeIcon = L.icon({
      iconUrl: "./pot-hole.png",
      iconSize: [70, 42],
      iconAnchor: [35, 21],
      popupAnchor: [0, -21],
    });

    const brokenSignIcon = L.icon({
      iconUrl: "./broken-sign.png",
      iconSize: [70, 42],
      iconAnchor: [35, 41],
      popupAnchor: [0, -21],
    });

    const missingSignIcon = L.icon({
      iconUrl: "./missing-sign.png",
      iconSize: [70, 42],
      iconAnchor: [35, 41],
      popupAnchor: [0, -21],
    });

    iconRef.current["pot hole"] = potholeIcon;
    iconRef.current["damaged sign"] = brokenSignIcon;
    iconRef.current["missing sign"] = missingSignIcon;

    return () => {
      // should remove?
      // iconRef.current["pot hole"].remove();
    };
  }, []);

  useEffect(() => {
    if (
      !iconRef.current ||
      !iconRef.current["pot hole"] ||
      !iconRef.current["damaged sign"] ||
      !iconRef.current["missing sign"]
    ) {
      return;
    }
    for (const problem of problemSet) {
      if (problem.marker) {
        const { marker, problemType } = problem;
        if (
          marker &&
          iconRef.current[problemType] // should always be true
        ) {
          marker.setIcon(iconRef.current[problemType]);
        } else {
          console.error("no marker or invalid problemType settting up problem");
        }
      } // if
    } // for
  }, [problemSet, iconRef.current]);
  const getTime = () => new Date().getTime();

  useEffect(() => {
    // console.log(
    //   "fetchingImages:",
    //   fetchingImages,
    //   "displayProblemImages:",
    //   config?.displayProblemImages,
    // );
    if (fetchingImages || !config || !config.displayProblemImages || allProblemPhotosLoaded)
      return () => {};

    let firstTwoDone : any = null;
    let lastDone : any = null;
    if (problemSet.length >= 2) {
      const firstProblem = problemSet[0];
      const secondProblem = problemSet[1];

      if (!firstProblem.image || !secondProblem.image) {
        setFetchingImages(true);
        const t0 = getTime();
        getProblemImages([firstProblem.id, secondProblem.id])
          .then((imageStructs) => {
            const t1 = getTime();
            console.log("useEffect: Time to download photos = ", t1 - t0);
            firstTwoDone = true;
            return handleProblemsWithImages(imageStructs);
            if (lastDone) {
              setAllProblemPhotosLoaded(true)
            }
          })
          .catch((ex) => {
            console.error(ex);
            setNeedsToLoadImages();
            setFetchingImages(false);
            firstTwoDone = false;
          })
          .finally(() => {
            if (!firstTwoDone)
              setNeedsToLoadImages();
          });
      }
    } else {
      firstTwoDone = true;
    }
    
    // image less problem id set.
    const idSet = ((problemSet.length >= 2) ? problemSet.slice(2) : problemSet).filter( p => !p.image ).map( p => p.id ); 
    (async ()=>{
        try {
          for (let i = 0; i < idSet.length; i += 5) {
            const imageStructs = await getProblemImages(idSet.slice(i, Math.min(i+5, idSet.length)));
            handleProblemsWithImages(imageStructs);
          } // for     
          if (firstTwoDone) {
            setAllProblemPhotosLoaded(true);
          }
          lastDone = true;
        } catch (ex) {
          console.error(ex);
          setNeedsToLoadImages();
          setFetchingImages(false);
          lastDone = false;
        }
    })();
    
    return () => {};
  }, [needsToLoadImages]);

  const handleProblemsWithImages = (imageStructs) => {
    const problemsWithMarkers = problemSet;
    // console.log(
    //   "handling problems with images",
    //   imageStructs.map((p) => p.id),
    // );
    let imageHash: { [id: number]: Problem } = {};
    if (
      JSON.stringify(imageStructs.map((p) => p.id)) ===
      JSON.stringify(problemsWithMarkers.map((p) => p.id))
    ) {
      console.log("Order is preserved.  Does this happen alot?");
    }

    const renderInMap = (p, image) => {
      //  obsolete. done in an effect.
      // See: @renderInMapEffect
    };

    try {
      let errorFlag = false;
      let alteredProblem = false;
      let imagesToAdd = imageStructs.length;
      for (const image of imageStructs) {
        imageHash[image.id] = image;
      }
      problemSet.forEach((p) => {
        ((param: string) => {
          const byteaBuffer = Buffer.from(param);
          const bigImage = byteaBuffer.toString("utf-8");

          if (bigImage === "") {
            setNeedsToLoadImages();
          } else if (bigImage.length < 2e5) {
            const newProblemSet = [...problemSet];
            const thisProblem = newProblemSet.find((q) => q.id === p.id);
            if (thisProblem) {
              thisProblem.image = bigImage;
              setProblemSet(newProblemSet);
              setFetchingImages(--imagesToAdd > 0);
              renderInMap(p, bigImage);
            } else {
              // deleted problem while rendering: abandon this!
              setFetchingImages(false);
            }
          } else {
            alteredProblem = true;
            shrinkPhoto(bigImage, 800).then((imageStruct) => {
              const { url, width, height } = imageStruct;
              const id = p.id;
              console.log({
                id,
                width,
                height,
                image: url.slice(0, 24) + "...",
              });
              const image = url;
              const newProblemSet = [...problemSet];
              const thisProblem = newProblemSet.find((q) => q.id == id);
              if (thisProblem) {
                thisProblem.image = image;
                setProblemSet(newProblemSet);
                setFetchingImages(--imagesToAdd > 0);
                renderInMap(p, image);
              } else {
                // deleted problem while rendering: abandon this!
                setFetchingImages(false);
                console.log(
                  " update failed because entry is missing.  Deleted?",
                );
              }
            });
          }
        })(imageHash[p.id]?.image ?? "");
      });
    } finally {
    }
  };

  // returns a promise to a string which will be an empty if successful or
  // an English language message indicating the nature of the failure.
  const loadProblems = useCallback(
    async (problemType: string): Promise<string> => {
      setAllProblemPhotosLoaded(false);
      if (loadingProblems) {
        console.log("Not loadingProblems because ", { loadingProblems });
        return "already loading";
      }
      try {
        let problems: void | Array<Problem>;
        setLoadingProblems(true);
        problems = await Promise.any([
          searchProblems(problemType, citySearch, language, sessionId),
          sleep(fetchTimeout),
        ]);
        if (!!problems) {
          for (
            let problem_index = 0;
            problem_index < problems.length;
            ++problem_index
          ) {
            const problem = problems[problem_index];
            console.log(problem);
            const { id, title, location, body, problemType } = problem;
            const { decLatitude, decLongitude } = location;
            const markerIcon = iconRef?.current[problemType];
            //console.log(markerIcon);
            //console.log(`icon: `, JSON.stringify(markerIcon));
            const marker = L.marker([decLatitude, decLongitude], {
              icon: markerIcon,
              opacity: problemType === "pot hole" ? 0.5 : 1,
              draggable: roles.includes("admin"),
            });
            marker.on("click", (ev) => {
              marker.openTooltip([decLatitude, decLongitude]);
              setSelectedProblemId(id);
              scrollToProblem(id);
            });
            marker.on("dragend", (ev) => {
              console.log(ev);
              const { target } = ev;
              if (target) {
                const { _latlng } = target;
                if (_latlng && isNumber(_latlng.lat) && isNumber(_latlng.lng)) {
                  if (problems) {
                    const first = problems.slice(0, problem_index);
                    const second = problems.slice(problem_index + 1);
                    let location = {
                      decLatitude: _latlng.lat,
                      decLongitude: _latlng.lng,
                    };
                    setProblemSet([
                      ...first,
                      {
                        ...problem,
                        location,
                      },
                      ...second,
                    ]);
                    setChangeList([...changeList, { id, location }]);
                  } // if
                } // if
              } // if
              scrollToProblem(id);
            });
            if (problem.marker && problem.marker.off) {
              problem.marker.off();
            }
            problem.marker = marker;
          } // for problem
          setProblemSet(problems);
          return "";
        } // if problems
      } catch (e) {
        console.error(e);
        setErrorMessage(_t("problem.database-update-failure"));
        return "some-exception";
      } finally {
        setNeedsToLoadImages();
        setLoadingProblems(false);
      }
      return "timeout error";
    },
    [setErrorMessage, setProblemSet, citySearch, fetchTimeout],
  );

  const setProblemsType = useCallback(
    (newValue) => {
      console.log("Loading problems due to a setProblemsType call");
      if (loadingProblems) {
        // deny!
        return;
      }
      onlySetProblemsType(newValue);
      loadProblems(newValue);
    },
    [loadingProblems, loadProblems, onlySetProblemsType],
  );

  const openProblem = (id: number) => {
    if (selectedProblemId === id) {
      document.title = websiteName;
      setSelectedProblemId(null);
    } else {
      const problem = problemSet.find((x) => x.id === id);
      if (problem) {
        document.title = problem.title;
      }
      setSelectedProblemId(id);
    }
  };

  if (loadingProblems) return <span>{_t("g.loading")}</span>;

  if (!loadedProblems)
    return (
      <button onClick={(ev) => loadProblems("any")}>{_t("g.try-again")}</button>
    );

  const setProblemRow = (problemChange: OptionalProblem) => {
    let problemIndex = -1;
    //console.log(`setProblemRow(`, problemChange, `...)`);
    let oldProblem;
    for (let i = 0; i <= problemSet.length; ++i) {
      if (problemSet[i].id === problemChange.id) {
        problemIndex = i;
        oldProblem = problemSet[i];
        break;
      }
    }

    if (problemIndex === -1) {
      console.error("Problem not found!");
      return;
    }

    const modifiedProblem = { ...oldProblem, ...problemChange };

    setProblemSet([
      ...problemSet.slice(0, problemIndex),
      modifiedProblem,
      ...problemSet.slice(problemIndex + 1),
    ]);
  };

  return !!showProjectSubmissionForm ? (
    <SubmitProject
      activeUserBitcoinCashAddress={activeUserBitcoincashAddress ?? ""}
      activeUserNumber={activeUserNumber}
      activeUserEthereumAddress={activeUserEthereumAddress}
      config={config}
      problemSet={problemSet}
      sessionId={sessionId}
      setErrorMessage={setErrorMessage}
      setShowProjectSubmissionForm={setShowProjectSubmissionForm}
      selectedProblemId={selectedProblemId}
      setSelectedProblemId={setSelectedProblemId}
    />
  ) : (
    <div id="problemSection" style={props.style}>
      <div id="leftBrainProblemSection">
        <div className="horizontallyArranged">
          <div className="form-line">
            <label>{_t("problem.type")}</label>
            <select
              name="search-type"
              id="search-type"
              defaultValue={problemsType}
              onChange={(ev) => setProblemsType(ev.target.value)}
            >
              <option value="any">{_t("problem.any")}</option>
              <option value="damaged sign">{_t("problem.damaged sign")}</option>
              <option value="pot hole">{_t("problem.pot hole")}</option>
              <option value="missing sign">{_t("problem.missing sign")}</option>
            </select>
          </div>
          <div className="form-line">
            <label>{_t("problem.city")}</label>
            <input
              type="text"
              className="cityName"
              list="cities"
              placeholder={_t("problem.city")}
              onChange={(ev) => setCitySearch(ev.target.value)}
              defaultValue={citySearch}
            />
            <datalist id="cities">
              {Object.keys(cities).map((name) => (
                <option value={name} key={name} />
              ))}
            </datalist>
          </div>
        </div>
        {problemSet.length === 0 ? (
          <h1>{_t("problem.none")}</h1>
        ) : (
          <div id="problemList" ref={problemListRef}>
            <table id="problemTable" ref={problemTableRef}>
              <thead>
                <tr ref={problemTableHeaderRef} id="problemTableHeader">
                  {roles.includes("admin") && <th />}
                  <th>{_t("problem.title")}</th>

                  <th className="wide">{_t("problem.type")}</th>

                  <th className="wide">{_t("problem.address")}</th>

                  <th className="wide">{_t("problem.coordinates")}</th>

                  <th className="wide">{_t("problem.description")}</th>

                  {roles.includes("provider") && <th />}

                  {roles.includes("admin") && <th />}
                </tr>
              </thead>
              <tbody ref={problemTableBodyRef} id="problemTableBody">
                {problemSet.map(
                  (x) =>
                    x && (
                      <ProblemListRow                        
                        activeUserBitcoincashAddress={
                          activeUserBitcoincashAddress
                        }
                        allProblemPhotosLoaded={allProblemPhotosLoaded}
                        changeList={changeList}
                        defaultPhotoEncoding={defaultPhotoEncoding}
                        defaultImage={defaultImage}
                        problemId={x.id}
                        key={x.id}
                        selected={selectedProblemId === x.id}
                        currentCity={currentCity}
                        fetchingImages={fetchingImages}
                        fetchTimeout={fetchTimeout}
                        setErrorMessage={setErrorMessage}
                        openProblem={openProblem}
                        setProblemRow={setProblemRow}
                        sessionId={sessionId}
                        setSelectedProblemId={setSelectedProblemId}
                        setShowProjectSubmissionForm={
                          setShowProjectSubmissionForm
                        }
                        setZoom={setZoom}
                        showProjectSubmissionForm={showProjectSubmissionForm}
                        roles={roles}
                        description={x.body ?? ""}
                        {...x}
                        deleteProblem={deleteProblem}
                        zoom={zoom ?? 0}
                      />
                    ),
                )}
                {
                  null /*The following blank row is to make the last element always visible in all browsers*/
                }
                <tr id="problemListlastTableRow">
                  <td id="problemListlastTableCell">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ProblemMap
        mapId="problemMap"
        style={{}}
        title={websiteName}
        cityDecimalLatitude={cityInfo?.lat}
        cityDecimalLongitude={cityInfo?.lon}
        problemSet={problemSet}
        place={cityInfo}
        setProblemSet={setProblemSet}
        setSelectedProblemId={setSelectedProblemId}
        setZoom={setZoom}
        scrollToProblem={scrollToProblem}
        selectedProblemId={selectedProblemId}
        zoom={zoom}
      />
    </div>
  );
} // function
export interface ProblemsProps {
  userId: string;
  language: string;
  projects: Array<Project>;
  session_id: string;
  appendProblem: (
    projectId: number,
    streetNumber: number,
    streetName: string,
    description: string,
    latitude: number,
    longitude: number,
    image: string | null,
  ) => void;
  setError: (errorMessage: string) => void;
  userBitcoinAddress: string;
}

const toExport = { ProblemList };
export default toExport;
