import axios from "axios";
import { isString, isObject } from "./sdptypes";
export type BoundsNumericArray = Array<Array<number>>;
export type BoundingBox = Array<Array<string>>;

function isArrayOfLength2(y: any): y is any[2] {
  console.log(6, y);
  if (typeof y !== "object") return false;
  console.log(8, y);
  if ("0" in y && "1" in y && "length" in y) {
    console.log(10, y);
    const x = y as { "0": any; "1": any; length: any };
    return x.length === 2;
  }
  console.log(14, y);
  return false;
}

export function isBoundsNumericArray(x: any): x is BoundsNumericArray {
  if (!isArrayOfLength2(x)) return false;
  const y = x as any[2];
  for (const z of y) {
    if (!isArrayOfLength2(z)) return false;
    const a = z as any[2];
    if (typeof a[0] !== "number" || typeof a[1] !== "number") return false;
  }
  return true;
}

export function isBoundsStringArray(x: any): x is BoundingBox {
  if (!isArrayOfLength2(x)) return false;
  const y = x as any[2];
  for (const z of y) {
    if (!isArrayOfLength2(z)) return false;
    const a = z as any[2];
    if (typeof a[0] !== "string" || typeof a[1] !== "string") return false;
    if (isNaN(parseFloat(a[0])) || isNaN(parseFloat(a[1]))) return false;
  }
  return true;
}

export function isBoundingBoxStringQuad(x: any): x is string[] {
  try {
    const y = x as string[4];
    // a thing that is not an array will throw in this block
    if (y.length !== 4) return false;
    for (const z of y) {
      if (typeof z !== "string") return false;
      const a = parseFloat(z);
      if (typeof a !== "number" || isNaN(a)) return false;
    }
  } catch (e) {
    return false;
  }
  return true;
}

interface NumericGeoPoint {
  lat: number;
  lng: number;
}

export function parseBoundingBoxStringQuad(boundingBox: string[]): Array<any> {
  const southEastCorner = {
    lat: parseFloat(boundingBox[0]),
    lng: parseFloat(boundingBox[3]),
    alt: 0,
  };
  const northWestCorner = {
    lat: parseFloat(boundingBox[1]),
    lng: parseFloat(boundingBox[2]),
    alt: 0,
  };
  return [southEastCorner, northWestCorner];
}

interface NumberShortCoordinates {
  lon: number;
  lat: number;
}

interface LongCoordinates {
  longitude: string;
  latitude: string;
}

interface BasePlace {
  addresstype: string;
  // neither camel case nor snake case...
  class: string;
  display_name: string;
  name: string;
  osm_type: string;
  place_id: number;
  place_rank: number;
  type: string;
}

function isBasePlace(x: any): x is BasePlace {
  for (const key of [
    "addresstype",
    "class",
    "display_name",
    "name",
    "osm_type",
    "place_id",
    "place_rank",
    "type",
  ]) {
    if (x[key] === undefined) return false;
  }
  return true;
}

export interface Way {
  type: "way";
  nodes: Array<number>;
  tags: { name: string; [id: string]: string };
  id: number;
}

export interface Relation {
  id: number;
  members: Array<any>;
  tags: { name: string; [id: string]: string };
  type: "relation";
}

export interface Place extends BasePlace {
  boundingbox: string[]; // each string is decimal number of latitude or longitude.
  lat: string; // decimal
  lon: string; // decimal
}

export function isPlace(x: any): x is Place {
  return (
    isObject(x) &&
    isString(x.lat) &&
    isString(x.lon) &&
    !!x.boundingbox &&
    x["name"] !== undefined &&
    x["addresstype"] !== undefined &&
    isBasePlace(x)
  );
}

export interface ExtendedPlace extends Place {
  address: {
    suburb: string;
    city: string;
    state_district: string;
    state: string;
    "ISO3166-2-lvl4": string;
    country: string;
    country_code: string;
  };
}

export interface NumericPlace extends BasePlace {
  boundingbox: number[]; // each string is decimal number of latitude or longitude.
  lat: number; // decimal
  lon: number; // decimal
}

export function parsePlace(place: Place): NumericPlace {
  return {
    ...place,
    lat: parseFloat(place.lat),
    lon: parseFloat(place.lat),
    boundingbox: place.boundingbox.map(parseFloat),
  };
}

/**
 * return sthe fraction part of a number.
 * NB:  It works for negative numbers:  fraction(-45.5) will return -0.5.
 */
const fraction = (x: number) => {
  return x - Math.trunc(x);
};

/**
 * returns a sexigesimal representation of a floating point number.  The bearings string should be "NS" or "EW" for bearing names (in English),
 * and determine which bearing to use when positive or negative floats are passed in.
 *
 * Example output: `45º52'32.41"S`
 *
 */
const decimalToSexigesimal = (
  latitude: number,
  bearings: string = "NS",
): string => {
  try {
    let bearing = bearings.charAt(0);
    if (latitude < 0) {
      latitude = -latitude;
      bearing = bearings.charAt(1);
    }
    const latitudeDegrees = Math.trunc(latitude);
    const latitudeMinutesDecimal = 60 * (latitude - latitudeDegrees);
    const latitudeMinutes = Math.floor(latitudeMinutesDecimal);
    // this will be resolution of at least a foot.
    const latitudeSeconds =
      Math.round(60 * 100 * fraction(latitudeMinutesDecimal)) / 100;

    if (
      isNaN(latitudeDegrees) ||
      isNaN(latitudeMinutes) ||
      isNaN(latitudeSeconds)
    )
      throw new Error(
        "Error calculating decimalToSexigisimal with one value becoming NaN",
      );

    return `${latitudeDegrees}º${latitudeMinutes}'${latitudeSeconds}"${bearing}`;
  } catch (e) {
    console.log(e);
    return "";
  }
};

/**
 * returns the whole number representation of the number of centiseconds equivalent to the floating point number of degrees passed in.
 **/
export const decimalToCentiseconds = (latitude: number): number => {
  return Math.round(360000 * latitude);
};

/**
 * return a whole number representation of the number of arch milliseconds as represented with the sexigesimal string sex and the bearings string.
 *
 * The bearings string should be "NS" or "EW" in English.  If neither bearing letter appears at the end of the string an Error is thrown.
 *
 *
 * Example returned values look like -16516278 (which mean 45º52'S) and so on.
 */
const sexigesimalToCentiseconds = (sex: string, bearings: string): number => {
  if (!sex.length) {
    throw new Error("Passed string is empty");
  }

  const sign = ((passedBearing: string) => {
    if (
      passedBearing !== bearings.charAt(0) &&
      passedBearing !== bearings.charAt(1)
    ) {
      throw new Error(
        `Passed bearing wrong type: ${passedBearing} ${bearings}`,
      );
    } else if (passedBearing === bearings.charAt(0)) {
      return 1;
    } else {
      return -1;
    }
  })(sex.charAt(sex.length - 1));

  const m = sex.match("^([0-9]+)º([0-9]+)'([0-9]+(.[0-9]+)?)\".");
  if (!m)
    throw new Error("Passed sexigisimal doesn't pass pattern recognition");
  return (
    sign *
    Math.round(
      parseInt(m[1]) * 360000 + parseInt(m[2]) * 6000 + parseFloat(m[3]) * 100,
    )
  );
};

const sexigesimalToDecimal = (sex: string, bearings: string): number => {
  return sexigesimalToCentiseconds(sex, bearings) / 360000;
};

export const getOSMData = async (
  country: string,
  city: null | string = null,
  streetName: null | string = null,
) => {
  let url = `https://nominatim.openstreetmap.org/search?q=`;
  if (streetName) {
    url += `${streetName}+`;
  }
  if (city) {
    url += `${city}+`;
  }
  url += `${country}&format=json`;
  const response = await axios.get(url);
  return response;
};

export function latLngAverage(results: Array<Place>): LongCoordinates {
  if (results.length > 0) {
    const coordinateSum: NumberShortCoordinates = { lat: 0, lon: 0 };
    for (let i = 0; i < results.length; ++i) {
      coordinateSum.lat += parseFloat(results[i].lat);
      coordinateSum.lon += parseFloat(results[i].lon);
    }

    const coordinates = {
      latitude: "" + coordinateSum.lat / results.length,
      longitude: "" + coordinateSum.lon / results.length,
    };

    return coordinates;
  } else {
    return { latitude: "NaN", longitude: "NaN" };
  }
}

async function getCoordinates(
  streetName: string,
  city: string,
  country: string,
): Promise<LongCoordinates> {
  const url = `https://nominatim.openstreetmap.org/search?street=${streetName}+${city}+${country}&format=json`;
  let results: Array<Place> = [];
  try {
    const response = await axios.get(url);
    results = response.data;
  } catch (error: any) {
    let message: string = JSON.stringify(error);
    if (typeof error.message === "string") {
      message = error.message as string;
    }
    throw new Error("Error fetching coordinates:" + message);
  }
  if (results.length > 0) {
    const coordinateSum: NumberShortCoordinates = { lat: 0, lon: 0 };
    for (let i = 0; i < results.length; ++i) {
      coordinateSum.lat += parseFloat(results[i].lat);
      // @ts-ignore
      coordinateSum.lon += parseFloat(results[i].lon);
    }

    const coordinates: LongCoordinates = {
      latitude: "" + coordinateSum.lat / results.length,
      longitude: "" + coordinateSum.lon / results.length,
    };

    return coordinates;
  } else {
    throw new Error("No results found for the given address.");
  }
}

type Point = number[];

export interface OSMEvent {
  originalEvent: PointerEvent;
  containerPoint: Point;
  latlng: { lat: number; lng: number };
  layerPoint: Point;
  sourceTarget: unknown;
  target: unknown;
  type: string;
}

export {
  getCoordinates,
  sexigesimalToCentiseconds,
  decimalToSexigesimal,
  sexigesimalToDecimal,
};

export const Comodoro_Rivadavia = {
  place_id: 196915,
  licence:
    "Data © OpenStreetMap contributors, ODbL 1.0. http://osm.org/copyright",
  osm_type: "relation",
  osm_id: 3732554,
  lat: "-45.8632024",
  lon: "-67.4752615",
  class: "boundary",
  type: "administrative",
  place_rank: 14,
  importance: 0.484679386419411,
  addresstype: "city",
  name: "Municipio de Comodoro Rivadavia",
  display_name:
    "Municipio de Comodoro Rivadavia, Departamento Escalante, Chubut, Argentina",
  boundingbox: ["-46.0000174", "-45.7173043", "-67.7824527", "-67.3422539"],
};

export const wideZoom = 11;
export const focusZoom = 19;
// In my tests setting maxZoom to 20 or higher results invalid request errors from server once you zoom in so much.
export const maxZoom = 19;

/* returns true if location is inside place. */
export function isInside(location: string, place: Place): boolean {
  const pbb = parseBoundingBoxStringQuad(place.boundingbox);
  const [lat, lng] = location.split(/,/).map(parseFloat);
  return (
    pbb[0].lat < lat && lat < pbb[1].lat && pbb[1].lng < lng && lng < pbb[0].lng
  );
}

export function getSupportedArea(location: string): Place | null {
  if (isInside(location, Comodoro_Rivadavia)) {
    return Comodoro_Rivadavia;
  } else {
    return null;
  }
}

export const reverse = async (
  lat: number,
  lon: number,
  zoom: number,
  addressDetails: number = 1,
): Promise<ExtendedPlace[]> => {
  const baseUrl = "https://nominatim.openstreetmap.org/reverse";
  const params = {
    format: "json",
    lat: String(lat),
    lon: String(lon),
    zoom: String(zoom),
    addressdetails: String(addressDetails),
  };
  const response = await axios.get<Place | Place[]>(baseUrl, { params });

  if (response.status === 200) {
    const data: unknown = response.data;
    const place = data as ExtendedPlace;
    const places = data as Array<ExtendedPlace>;
    try {
      if (isPlace(data)) {
        return [place];
      }
      throw new Error("internal-error");
    } catch (e) {
      if (!places.length) {
        console.log({ places });
        throw new Error("invalid response");
      } else {
        return places;
      }
    }
  } else {
    throw new Error("unsuccessful resquest");
  }
};

export async function overPassExample(): Promise<any> {
  return fetch(
    `https://overpass-api.de/api/interpreter?data=[out:json][timeout:25];way["highway"="residential"](51.1169,12.7434,51.1316,12.7923);out geom`,
  );
}

async function getStreetsForBoundingBox(
  boundingBox: Array<NumericGeoPoint>,
): Promise<any> {
  const [sE, nW] = boundingBox;

  return fetch(
    `https://overpass-api.de/api/interpreter?` +
      `data=[out:json][timeout:25];` +
      `(` +
      `way["highway"](${sE.lat},${nW.lng},${nW.lat},${sE.lng});` + // Fetch streets
      `node["place"="city"](${sE.lat},${nW.lng},${nW.lat},${sE.lng});` + // Fetch city
      `relation["boundary"="administrative"]["admin_level"~"4|6"](${sE.lat},${nW.lng},${nW.lat},${sE.lng});` + // Fetch province/state
      `relation["boundary"="administrative"]["admin_level"="2"](${sE.lat},${nW.lng},${nW.lat},${sE.lng});` + // Fetch country
      `);` +
      `out body;`,
  );
}

export interface IdNamePair {
  id: number;
  name: string;
}

const initial_size = 20; /*m*/

export interface GetStreetsAroundSuccessfulReturnType {
  relations: Array<Relation>;
  ways: Array<Way>;
}

export async function getStreetsAround(
  lat: string | number,
  lng: string | number,
  nS: string,
  eW: string,
): Promise<number | GetStreetsAroundSuccessfulReturnType> {
  let decLatitude: number = 0;
  let decLongitude: number = 0;
  try {
    if (typeof lat === "string") {
      decLatitude = sexigesimalToDecimal(lat, nS);
    } else {
      decLatitude = lat;
    }
    if (typeof lng === "string") {
      decLongitude = sexigesimalToDecimal(lng, eW);
    } else {
      decLongitude = lng;
    }

    console.log("getStreetsAround:", lat, lng, decLatitude, decLongitude);

    const cosLat = Math.cos((decLatitude / 180) * Math.PI);
    // No streets at the poles.
    if (cosLat === 0) {
      // The poles are outside of our support.
      return { relations: [], ways: [] };
    }

    let relations: Array<Relation> = [];
    let streetList: null | Array<Way> = null;

    for (let size = initial_size; size < 20001; size *= 100) {
      console.log(
        `Iterating looking in a sign of size ${size} m N,E,S,W of the given point.`,
      );
      // 30m is too small. 100m should be too big.
      const delta = size /*m*/ / 111_111.111; /* meters/degree */ // *=cosine(latitude) is the size at other latitudes.

      const southEastCorner = {
        lat: decLatitude - delta,
        lng: decLongitude + delta / cosLat,
      };
      const northWestCorner = {
        lat: decLatitude + delta,
        lng: decLongitude - delta / cosLat,
      };

      const resp = await getStreetsForBoundingBox([
        southEastCorner,
        northWestCorner,
      ]);

      if (!resp.ok) {
        const { statusText } = resp;
        console.error(statusText);
        if (streetList === null) return 9;
        else return { relations, ways: streetList };
      }

      try {
        const jSON = await resp.json();
        console.log(jSON);
        const { elements } = jSON;
        const dictionary: { [id: string]: Way } = {};
        if (elements.length === 0) continue;
        const relations = elements.filter(
          (element) => element.type === "relation",
        );
        const ways = elements.filter((element) => element.type === "way");
        console.log(elements);
        ways.forEach((element) => {
          if (element?.tags?.name && element?.type === "way")
            dictionary[element?.tags?.name] = element;
        });

        streetList = Object.values(dictionary);
        if (streetList && streetList.length !== 0) {
          return { relations: relations, ways: streetList };
        }
      } catch (e) {
        console.error(e);
        if (streetList === null) return 20;
        else return { relations, ways: streetList };
      }
    } // for
    // user must have chosen some place outside of a city.  Giving up.
    return { relations: [], ways: [] };
  } catch (e) {
    // invalid input
    console.error(e);
    return 1;
  }
}
