export type ViewType =
  | "profile"
  | "projects"
  | "register"
  | "networks"
  | "login"
  | "users"
  | "report"
  | "disconnected";

export function isViewType(x: string): x is ViewType {
  return !![
    "profile",
    "projects",
    "register",
    "networks",
    "login",
    "users",
    "report",
    "disconnected",
  ].includes(x);
}

export interface StandardException {
  message: string;
  stack: any;
}

export function isStandardException(e: unknown): e is StandardException {
  if (
    !!e &&
    typeof e === "object" &&
    "message" in e &&
    typeof e.message === "string" &&
    "stack" in e
  ) {
    return true;
  } else {
    console.log(e);
  }
  return false;
}

export function isNull(n: unknown): n is null {
  return n === null;
}

export function isString(s: unknown): s is string {
  return typeof s === "string";
}

export function isNumber(n: unknown): n is number {
  return typeof n === "number";
}

export function isBoolean(b: unknown): b is boolean {
  return b === true || b === false;
}

export function isObject(o: unknown): o is Object {
  return typeof o === "object";
}

export function isDate(d: any): d is Date {
  return (
    typeof d === "object" &&
    Object.keys(d).length === 0 &&
    typeof d.toUTCString === "function" &&
    typeof d.getTime === "function"
  );
}

// can be done?  possible return values for typeof are: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"
//export function isVoid(v: unknown): v is void {

export interface FailedGeneralResponse {
  success: false;
  errorMessage: string;
}

export interface SuccessfulGeneralResponse {
  success: true;
}

export interface FailedAuthenticateResponse extends FailedGeneralResponse {}

export interface SuccessfulAuthenticateResponse
  extends SuccessfulGeneralResponse {
  id: number;
  given_names: string[];
  family_names: string[];
  admin: 0 | 1;
  donor: "0" | "1";
  provider: "0" | "1";
}

export function isFailedAuthenticateResponse(
  r: unknown,
): r is FailedAuthenticateResponse {
  try {
    const { errorMessage, success } = r as FailedAuthenticateResponse;
    const keys: string[] = Object.keys(r as object);
    return (
      keys.length === 2 &&
      keys.includes("success") &&
      keys.includes("errorMessage") &&
      success === false &&
      isString(errorMessage)
    );
  } catch (e) {
    return false;
  }
}

export interface SetPasswordResponse {
  success: boolean;
  errorMessage?: string;
}

export function isSetPasswordResponse(r: unknown): r is SetPasswordResponse {
  if (isObject(r) && Object.keys(r).includes("success")) {
    const s = r as { success: unknown };
    const t = r as { success: unknown; errorMessage: unknown };
    return (
      s.success === true ||
      (s.success === false && typeof t.errorMessage === "string")
    );
  }
  return false;
}

export function sleep(delay: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

export type SupportedLanguageCountryPair = "en-US" | "es-AR";

export function isSupportedLanguageCountryPair(
  s: any,
): s is SupportedLanguageCountryPair {
  return ["en-US", "es-AR"].includes(s);
}

export interface LanguageSpec {
  score: number;
  bareLanguage: string;
  countryLanguage: string | null;
}

export interface EmailAddress {
  id: string;
  validated: boolean;
  verification_code: string;
}

export interface Profile {
  id: number;
  givenNames: string[];
  familyNames: string[];
  publicKeys: string[];
  emailAddresses: EmailAddress[];
  flags: string[];
  bitcoincashAddress: string | null;
}

export interface GetProfileFailedResponse {
  success: false;
  errorMessage: string;
}

export interface GetProfileSuccessfulResponse extends Profile {
  success: true;
}

export type GetProfileResponse =
  | GetProfileFailedResponse
  | GetProfileSuccessfulResponse;

// Determine the best language for the user.  Some users would like Argentinian Spanish ideally,
// but if they have a choice between American English and Spanish Spanish, they prefer American English.

// So if there is a match based on country language perfect, then take the best score of those.
// If there is no such match, take the best mach of the language only.
export function getBestLanguageCountry(
  acceptLanguageHeader: string | null,
): "en-US" | "es-AR" {
  let tempBestLangauge: string | null = null;

  if (!isString(acceptLanguageHeader)) {
    // no header bots can use English.
    return "en-US";
  }

  const unfilteredLanguageSpecs: Array<LanguageSpec> = acceptLanguageHeader
    .split(/,/)
    .map((lang) => {
      const [start, QEscore] = lang.split(/;/);
      const [lg, country] = start.split(/-/);
      let score = 1;
      try {
        score = parseFloat(QEscore.split(/q=/)[1]);
      } catch (e) {}
      return {
        score: score ?? 1,
        bareLanguage: lg,
        country,
        countryLanguage: start,
      };
    });

  let languageSpecs: Array<LanguageSpec> = unfilteredLanguageSpecs.filter(
    isSupportedLanguageCountryPair,
  );

  if (languageSpecs.length === 0) {
    languageSpecs = unfilteredLanguageSpecs.filter((langSpec) => {
      return ["es", "en"].includes(langSpec.bareLanguage);
    });
  }
  tempBestLangauge = languageSpecs.reduce(
    (a, b) => (a.score > b.score ? a : b),
    { score: 0, bareLanguage: "en", countryLanguage: null },
  ).countryLanguage;

  if (tempBestLangauge === "en") {
    return "en-US";
  } else if (tempBestLangauge === "es") {
    return "es-AR";
  } else {
    return tempBestLangauge as "en-US" | "es-AR";
  }
}

const exportedSymbols = { isStandardException, isString, isNumber };

export default exportedSymbols;
