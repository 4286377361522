import React from "react";
import { _t } from "../i18n/index";
import { useCallback, useState, useRef, useEffect } from "react";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { ViewType } from "../sdptypes";
import buffer from "buffer";
import { sleep, isStandardException, StandardException } from "../sdptypes";
import { AddressSelectionList } from "./addressselectionlist";
import { setCookie } from "../api";
import * as ls from "../local-storage";
import { GoogleButton } from "./google";
const Buffer = buffer.Buffer;
// I don't know where to find these
type Timeout = ReturnType<typeof setTimeout>;

interface Props {
  activeUserEthereuAddress: string;
  connectEthereumExtension: () => Promise<boolean>;
  activeUserFamilyNames: string[];
  activeUserGivenNames: string[];
  challengePhrase: null | string;
  challengePhraseExpiry: null | Date;
  ethereumAddresses: string[];
  emailAddressError: string | null;
  emailAddress: string;
  familyNamesError: string | null;
  fetchTimeout: number; // ms
  givenNamesError: string | null;
  handleError: (e: StandardException) => void;
  hasEthereumWallet: boolean;
  loggedIn: boolean;
  password: string;
  passwordError: string;
  setSessionInfo: (
    session: string | null,
    activeUserNumber: number | null,
    expiry: Date,
  ) => void;
  setActiveUserBitcoincashAddress: (account: string | null) => void;
  setActiveUserEthereumAddress: (account: string | null) => void;
  setCanConnectToBackend: (flag: null | boolean) => void;
  setCanConnectToEthereum: (flag: null | boolean) => void;
  setConnected: (connected: boolean) => void;
  setEmailAddress: (emailAddress: string) => void;
  setErrorMessage: (errorMessage: string) => void;
  setActiveUserFamilyNames: (activeUserFamilyNames: string) => void;
  setActiveUserNumber: (i: number) => void;
  setActiveUserGivenNames: (activeUserGivenNames: string) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setPassword: (s: string) => void;
  setRoles: (r: Array<string>) => void;
  sessionId: string;
  sessionExpiry: Date;
  setSubjectUserFamilyNames: (x: string) => void;
  setSubjectUserGivenNames: (x: string) => void;
  subjectUserFamilyNames: string;
  subjectUserGivenNames: string;
  setView: (view: ViewType) => void;
  view: ViewType;
}

export function Login(props: Props) {
  const {
    activeUserEthereuAddress,
    connectEthereumExtension,
    challengePhrase,
    challengePhraseExpiry,
    ethereumAddresses,
    emailAddress,
    emailAddressError,
    familyNamesError,
    fetchTimeout,
    givenNamesError,
    handleError,
    hasEthereumWallet,
    password,
    passwordError,
    setActiveUserBitcoincashAddress,
    setActiveUserEthereumAddress,
    setActiveUserFamilyNames,
    setActiveUserGivenNames,
    setActiveUserNumber,
    setCanConnectToBackend,
    setCanConnectToEthereum,
    setConnected,
    setEmailAddress,
    setErrorMessage,
    setLoggedIn,
    setPassword,
    setRoles,
    sessionId,
    sessionExpiry,
    setSessionInfo,
    setSubjectUserFamilyNames,
    setSubjectUserGivenNames,
    setView,
    subjectUserFamilyNames,
    subjectUserGivenNames,
    view,
  } = props;

  const timeoutHandler = useRef<null | Timeout>(null);
  const [onlineMode, setOnlineMode] = useState(true);
  const [userNumber, setUserNumber] = useState<number | null>(null);
  const [ethereumAddressToLogin, setEthereumAddressToLogin] = useState("");
  const [requestingAccount, setRequestingAccount] = useState(false);
  const [doingLogin, setDoingLogin] = useState(false);
  const [tentativeExpiry, setTentativeExpiry] = useState<"" | Date>("");
  const [addresses, setAddresses] = useState<Array<string>>(
    activeUserEthereuAddress ? [activeUserEthereuAddress] : [],
  );
  const [loginType, setLoginType] = useState("email-address");
  //const [subjectUserFamilyNames, setSubjectUserFamilyNames] = useState(activeUserFamilyNames.join(' '));
  //const [subjectUserGivenNames, setSubjectUserGivenNames] = useState(activeUserGivenNames.join(' '));

  const tooManyCalls = false;

  const mMProviderRef = useRef(0);
  const ethersProviderRef = useRef(null);

  //const [debugText, setDebugText] = useState("");
  const connectMetaMask = async (ev) => {
    ev.preventDefault();
    await connectEthereumExtension();
    try {
      if (!activeUserEthereuAddress && !requestingAccount) {
        if (hasEthereumWallet) {
          setRequestingAccount(true);
          // @ts-ignore
          const getEthereumAddress = window.ethereum.request({
            method: "eth_requestAccounts",
          });
          Promise.any([getEthereumAddress, sleep(18000 /*ms*/)])
            .then((ethereumAddresses) => {
              if (!ethereumAddresses) {
                throw new Error(_t("g.timeout"));
              }
              setCanConnectToEthereum(true);
              if (!ethereumAddresses) {
                setErrorMessage(_t("login.wallet-timeout"));
              } else {
                console.log(ethereumAddresses);
                if (ethereumAddresses.length === 0) {
                  setErrorMessage(_t("login.wallet-has-no-address"));
                }
                setEthereumAddressToLogin(ethereumAddresses[0]);
                setPassword("");
              } // if ethereumAddresses
              setRequestingAccount(false);
            }) // thne ethereumAddresses
            .catch((e) => {
              console.error(e);
              setCanConnectToEthereum(false);
              setErrorMessage(
                _t("register.could-not-connect-to-ethereum-account"),
              );
            })
            .finally(() => setRequestingAccount(false));
        } else {
          // same string is used in registration.tsx
          setErrorMessage(_t("register.could-not-connect-to-ethereum-account"));
        }
      }
    } catch (e) {
      setCanConnectToEthereum(false);
      setRequestingAccount(false);
      console.error(e);
      setErrorMessage(_t("login.unable-to-connect-wallet"));
    }
  };

  const doLogin = async (ev) => {
    console.log(challengePhrase);
    let subjectUserEthereumAddress;
    try {
      setDoingLogin(true);
      if (tooManyCalls) {
        setErrorMessage(_t("login.too-many-login-attempts"));
        return;
      }

      if (challengePhrase === null || challengePhraseExpiry === null) {
        return;
      }

      const headers = await (async () => {
        const session_id = challengePhrase as string;
        const expiry = challengePhraseExpiry as Date;

        if (session_id === null) {
          throw new Error(_t("login.cannot-connect-to-server"));
        }

        // @ts-ignore
        if (!window.ethereum && (!password || password === "")) {
          throw new Error(
            "No Ethereum address and no password sent for authentication.",
          );
          // @ts-ignore
        } else if (window.ethereum && (!password || password === "")) {
          if (!mMProviderRef?.current) {
            const val: null | number = await detectEthereumProvider({});
            if (val) mMProviderRef.current = val;
          }

          // @ts-ignore
          if (window.ethereum && ethersProviderRef.current === null) {
            // @ts-ignore
            ethersProviderRef.current = new ethers.providers.Web3Provider(
              // @ts-ignore
              window.ethereum,
            );
          }

          if (ethereumAddressToLogin === "") {
            // @ts-ignore
            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });

            if (!accounts || accounts.length === 0) {
              throw new Error("no accounts");
            }

            setAddresses(accounts);
            subjectUserEthereumAddress = accounts[0];
          } else {
            subjectUserEthereumAddress = ethereumAddressToLogin;
          }

          //const satoshis = await ethersProvider.send("eth_getBalance", accounts);
          //setNativeCoinBalance(ethers.utils.formatEther(satoshis));
          const { signature } = await (async (session_id) => {
            console.log("Setting session id and expiry", session_id, expiry);
            setSessionInfo(session_id, null, new Date(expiry));

            const hexMessage2 =
              "0x" + Buffer.from(session_id, "utf-8").toString("hex");

            const signature = await (() => {
              /* 
                So this looks like code that is going to be cryptic to the 
                user but instead, Metamask will present the plain text that
                we have in session_id (by reversing the operations we did
                to arrive at hexMessage2) */
              // @ts-ignore
              return window.ethereum.request({
                method: "personal_sign",
                params: [hexMessage2, subjectUserEthereumAddress],
              });
            })();

            // @ts-ignore
            const derivedAddressCheck = ethers.utils.verifyMessage(
              session_id,
              signature,
            );

            if (
              derivedAddressCheck.toLowerCase() !==
              subjectUserEthereumAddress.toLowerCase()
            ) {
              console.log("Use hex broken");
              throw new Error(
                "Hex algorithm didn't result in a good signature.",
              );
            }

            return { session_id, signature, subjectUserEthereumAddress };
          })(session_id);

          console.log({ subjectUserEthereumAddress, session_id });

          const headers = {
            session_id,
            signature,
            activeUserAddress: subjectUserEthereumAddress,
          };
          return headers;
        } else {
          // using password instead?

          console.log("Setting session id and expiry", challengePhrase, expiry);
          const headers = { password, session_id };

          if (subjectUserGivenNames.length > 0 && loginType === "names") {
            headers["givenNames"] = subjectUserGivenNames;
          }

          if (subjectUserFamilyNames.length && loginType === "names") {
            headers["familyNames"] = subjectUserFamilyNames;
          }

          if (userNumber && loginType === "user-number") {
            headers["userNumber"] = userNumber;
          }

          console.log({ emailAddress });
          if (emailAddress && loginType === "email-address") {
            headers["email_address"] = emailAddress;
          }

          return headers;
        }
      })();

      const { session_id } = headers;

      const authenticateJSON = await (async () => {
        try {
          const authenticateResponse = await Promise.any([
            fetch("/private-api/authenticate", {
              headers,
            }),
            sleep(fetchTimeout),
          ]);
          if (!authenticateResponse) {
            setErrorMessage(_t("g.timeout"));
            throw new Error("timeout");
          }
          setCanConnectToBackend(true);
          setOnlineMode(true);
          return await authenticateResponse.json();
        } catch (e) {
          setOnlineMode(false);
          setCanConnectToBackend(false);
          return {
            success: false,
            errorMessage: "Cannot fetch from the server.",
          };
        }
      })();

      const { success, expiry } = authenticateJSON;

      if (success === false) {
        const { errorMessage } = authenticateJSON;
        console.log(errorMessage);
        throw new Error(_t("login.bad"));
      }
      // /authenticate is not documented to give any profile data what so ever...

      const {
        id,
        bitcoincashAddress,
        given_names,
        family_names,
        admin,
        donor,
        provider,
        publicKeys,
      } = authenticateJSON;
      let roles: Array<string> = [];
      if (admin) roles.push("admin");
      if (donor) roles.push("donor");
      if (provider) roles.push("provider");
      console.log(authenticateJSON);
      setActiveUserBitcoincashAddress(bitcoincashAddress);
      setActiveUserFamilyNames(family_names);
      if (!family_names.length)
        console.log("family_names set wrong in DB:", given_names);
      setActiveUserGivenNames(given_names);
      if (!given_names.length)
        console.log("given_names set wrong in DB:", given_names);
      setRoles(roles);
      setActiveUserEthereumAddress(subjectUserEthereumAddress);
      setLoggedIn(true);
      setSessionInfo(session_id, id, new Date(expiry));
      const storedRoles = ls.get("roles");
      if (!storedRoles) {
        ls.set("roles", { [id]: roles });
      } else {
        storedRoles[id] = roles;
        ls.set("roles", storedRoles);
      }
      setConnected(true);
      if (view === "register" || view === "login") {
        setView("projects");
      }
      setSubjectUserFamilyNames("");
      setSubjectUserGivenNames("");
      setLoggedIn(true);
      setConnected(true);
    } catch (e) {
      setConnected(false);
      setActiveUserEthereumAddress(null);
      setRoles([]);
      setActiveUserFamilyNames("");
      setActiveUserGivenNames("");
      setSubjectUserFamilyNames("");
      setSubjectUserGivenNames("");
      setLoggedIn(false);
      setConnected(false);
      setSessionInfo("", null, new Date(0));
      if (isStandardException(e)) {
        const se = e as StandardException;
        handleError(se);
      }
    } finally {
      setDoingLogin(false);
    }
  };

  const OR = subjectUserGivenNames.length === 0 &&
    subjectUserFamilyNames.length === 0 &&
    emailAddress === "" &&
    userNumber == null && <h2>{_t("g.or")} ...</h2>;

  const loginDisabled =
    !!doingLogin ||
    (loginType === "email-address" &&
      (emailAddressError !== "" || emailAddress === "")) ||
    (loginType === "user-number" && userNumber === null) ||
    (loginType === "names" &&
      subjectUserGivenNames === "" &&
      subjectUserFamilyNames === "");

  return (
    <article id="login-page" className="App-page">
      {!doingLogin && (
        <div className="verticallyArranged">
          {_t("login.enter-names-or-user-number-or-email-address")}

          <select
            name="login-type"
            onChange={(ev) => setLoginType(ev.target.value)}
          >
            <option value="email-address">
              {_t("login.login with email address")}
            </option>
            <option value="user-number">
              {_t("login.login-with-user-number")}
            </option>
            <option value="names">real names</option>
            <option value="google">Google</option>
            {hasEthereumWallet && <option value="ethereum">Ethereum</option>}
          </select>
        </div>
      )}
      {doingLogin ? (
        _t("login.logging-in")
      ) : (
        <form className="login-form">
          {hasEthereumWallet && loginType === "ethereum" && (
            <>
              {userNumber == null &&
                subjectUserGivenNames.length === 0 &&
                subjectUserFamilyNames.length === 0 && (
                  <div>
                    <h3>{_t("login.login-with-ethereum-wallet")}</h3>
                    {ethereumAddressToLogin ? (
                      <div className="form-line">
                        <div>
                          <label>{_t("login.Your Ethereum Address")}: </label>
                        </div>
                        <div className="verticallyArranged">
                          <AddressSelectionList
                            address={ethereumAddressToLogin}
                            setAddress={setEthereumAddressToLogin}
                            addresses={ethereumAddresses}
                          />
                          <div className="form-line">
                            {challengePhrase !== null &&
                              challengePhraseExpiry !== null && (
                                <button onClick={doLogin} disabled={doingLogin}>
                                  {
                                    // @ts-ignore
                                    _t("g.login")
                                  }
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : requestingAccount ? (
                      _t("login.requesting-account")
                    ) : (
                      <button onClick={connectMetaMask}>
                        {_t("login.Connect Ethereum Address")}
                      </button>
                    )}
                  </div>
                )}
            </>
          )}

          {loginType === "google" && (
            <GoogleButton>{_t("login.login-with-google")}</GoogleButton>
          )}

          {loginType === "email-address" && (
            <div>
              <h3>{_t("login.login with email address")}</h3>
              <div className="form-line">
                <label>{_t("register.email-address")}</label>
                <input
                  type="email"
                  defaultValue={emailAddress}
                  onChange={(ev) => setEmailAddress(ev.target.value)}
                />
              </div>
              <span className="error">{emailAddressError}</span>
            </div>
          )}

          {loginType === "user-number" && (
            <div>
              <h3>{_t("login.login-with-user-number")}</h3>
              <div className="form-line">
                <label>{_t("login.user-number")}</label>
                <input
                  type="number"
                  onChange={(ev) =>
                    setUserNumber(
                      ev.target.value !== "" ? parseInt(ev.target.value) : null,
                    )
                  }
                  defaultValue={userNumber ? userNumber + "" : ""}
                />
              </div>
            </div>
          )}

          {loginType === "names" && (
            <div>
              <h3>{_t("login.login-with-real-names")}</h3>
              <div className="form-line">
                <label>{_t("register.givennames")}</label>
                <input
                  type="text"
                  defaultValue={subjectUserGivenNames}
                  onChange={(ev) => setSubjectUserGivenNames(ev.target.value)}
                />
              </div>

              {givenNamesError && (
                <>
                  <br />
                  <span className="error">{givenNamesError}</span>
                </>
              )}
              <div className="form-line">
                <label>{_t("register.familynames")}</label>
                <input
                  type="text"
                  defaultValue={subjectUserFamilyNames}
                  onChange={(ev) => setSubjectUserFamilyNames(ev.target.value)}
                />
              </div>
              {familyNamesError && (
                <>
                  <br />
                  <span className="error">{familyNamesError}</span>
                </>
              )}
            </div>
          )}

          {["names", "email-address", "user-number"].includes(loginType) &&
            !OR && (
              <>
                <div className="form-line">
                  <label>{_t("login.enter-password")}</label>
                  <input
                    type="password"
                    defaultValue={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                  />
                </div>
                <span className="error">{passwordError}</span>
              </>
            )}

          {["names", "email-address", "user-number"].includes(loginType) && (
            <div className="form-line">
              {challengePhrase !== null && challengePhraseExpiry !== null && (
                <button disabled={loginDisabled} onClick={doLogin}>
                  {
                    // @ts-ignore
                    _t("g.login")
                  }
                </button>
              )}
            </div>
          )}

          {OR}

          {OR && (
            <button onClick={() => setView("register")}>
              {_t("g.signup")}
            </button>
          )}

          <br />
        </form>
      )}
    </article>
  );
}
