import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ViewType,
  EmailAddress,
  GetProfileFailedResponse,
  GetProfileSuccessfulResponse,
  GetProfileResponse,
  isStandardException,
  sleep,
} from "../sdptypes";
import tr from "../i18n";
import cashaddr from "cashaddrjs";

const _t = (s: string): string => {
  return tr.translate(s) as string;
};

interface Props {
  activeUserBitcoinCashAddress: string;
  activeUserEthereumAddress: string;
  fetchTimeout: number;
  hasEthereumWallet: boolean;
  onlySetPassword2: (newPassword: string) => void;
  onlySetPassword: (newPassword: string) => void;
  password2Error: any;
  password2: string;
  passwordError: any;
  password: string;
  sessionId: any;
  setActiveUserBitcoinCashAddress: (addr: null | string) => void;
  setActiveUserEthereumAddress: any;
  setErrorMessage: (errorMessage: string) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setPassword2: any;
  setPassword2Error: any;
  setPassword: any;
  setPasswordError: any;
  setSessionInfo: (
    sessionId: string | null,
    userId: number | null,
    expiry: Date,
  ) => void;
  setSuccessMessage: (x: string) => void;
  setView: (v: ViewType) => void;
  userNumber: number;
}

export function Profile(props: Props): JSX.Element {
  const {
    activeUserBitcoinCashAddress,
    activeUserEthereumAddress,
    fetchTimeout,
    hasEthereumWallet,
    onlySetPassword,
    onlySetPassword2,
    password,
    password2,
    password2Error,
    passwordError,
    sessionId,
    setActiveUserBitcoinCashAddress,
    setActiveUserEthereumAddress,
    setErrorMessage,
    setPassword,
    setPassword2,
    setPassword2Error,
    setPasswordError,
    setSessionInfo,
    setSuccessMessage,
    setView,
    userNumber,
  } = props;
  const [constructorCount, setConstructorCount] = useState(0);
  const [destructorCount, setDestructorCount] = useState(0);
  const [givenNamesClickCount, setGivenNamesClickCount] = useState(0);

  const [givenNames, setGivenNames] = useState("");
  const [familyNames, setFamilyNames] = useState<string>("");
  const [flags, setFlags] = useState<Array<string>>([]);
  const [emailAddresses, setEmailAddresses] = useState<
    Array<Omit<EmailAddress, "verification_code">>
  >([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [ethereumAddresses, setEthereumAddresses] = useState<Array<string>>(
    activeUserEthereumAddress === "" ? [] : [activeUserEthereumAddress],
  );
  const [loadingEthereumAddresses, setLoadingEthereumAddresses] =
    useState(false);

  const [loadingProfileInformation, setLoadingProfileInformation] =
    useState(false);
  const [profileInformationLoaded, setProfileInformationLoaded] =
    useState(false);
  const [bitcoinCashAddress, setBitcoincashAddress] = useState<string | null>(
    activeUserBitcoinCashAddress,
  );
  const [savedBitcoincashAddress, setSavedBitcoincashAddress] = useState<
    string | null
  >(null);
  const [bitcoinCashAddressError, setBitcoincashAddressError] = useState("");

  const validationCodeInputRef = useRef<HTMLInputElement>(null);
  const [profileLoadFailureCount, setProfileLoadFailureCount] = useState(0);

  const [validationCode, setValidationCode] = useState("");
  const emailAddressError = "";
  const bitcoinEntryInputRef = useRef<HTMLInputElement>(null);

  const handleBitcoincashAddressChanged = (ev) => {
    try {
      let addr = ev.target.value;
      setBitcoincashAddress(addr);
      setBitcoincashAddressError("");
    } catch (e) {
      console.log("exception thrown here.");
      if (isStandardException(e)) setBitcoincashAddressError(e.message);
    }
  };

  const saveBitcoincashAddress = async () => {
    const restoreOld = () =>
      setTimeout(() => {
        console.log(`Hello restoring ${savedBitcoincashAddress}...`);
        if (bitcoinEntryInputRef.current) {
          bitcoinEntryInputRef.current.value = savedBitcoincashAddress ?? "";
        }
        setBitcoincashAddress(savedBitcoincashAddress);
      }, 1500);

    try {
      let addr: string | null = bitcoinCashAddress;
      if (
        !!addr &&
        addr != "" &&
        !addr.startsWith("bitcoincash:") &&
        !addr.startsWith("bchtest:") &&
        !addr.startsWith("bchreg:")
      ) {
        addr = "bitcoincash:" + addr;
      }
      if (addr != "") {
        cashaddr.decode(addr);
      }
      const setBitcoinAddressResponse = await Promise.any([
        fetch("/private-api/setBitcoincashAddress", {
          headers: [
            ["session_id", sessionId],
            ["bitcoinCashAddress", addr],
          ],
        }),
        sleep(fetchTimeout),
      ]);
      if (!setBitcoinAddressResponse) {
        setErrorMessage(_t("profile.error-setting-address"));
        restoreOld();
        return;
      }
      const setBitcoinAddressJSON = await setBitcoinAddressResponse.json();
      const { success, errorMessage } = setBitcoinAddressJSON;
      if (success) {
        if (addr != bitcoinCashAddress) {
          setSavedBitcoincashAddress(addr);
          if (bitcoinEntryInputRef.current) {
            bitcoinEntryInputRef.current.value = addr ?? "";
          }

          setBitcoincashAddress(addr);
        }
        setActiveUserBitcoinCashAddress(addr);

        setSuccessMessage(_t("profile.new-address-set"));
      } else {
        setErrorMessage(_t("profile.error-setting-address"));
        restoreOld();
      }
    } catch (e) {
      console.log("exception thrown here.", e);
      setBitcoincashAddressError(_t("profile.bad-addressformat"));
      if (isStandardException(e)) console.log(e.message);
      restoreOld();
    }
  };

  const AddEthereumAddresses = useCallback(() => {
    // @ts-ignore window
    if (hasEthereumWallet && window?.ethereum) {
      setLoadingEthereumAddresses(true);
      // @ts-ignore window
      return window?.ethereum
        .request({
          method: "eth_requestAccounts",
          params: [],
        })
        .then((params) => {
          console.log(params);
          return Promise.any([
            fetch("/private-api/setEthereumAddress", {
              headers: {
                session_id: sessionId,
                ethereumAddress: params[0],
              },
            }),
            sleep(fetchTimeout),
          ]).then((resp) => {
            if (!resp) {
              setErrorMessage(_t("g.timeout"));
            } else if (resp.ok) {
              resp.json().then((resp) => {
                console.log(resp);
                if (resp.success) {
                  setEthereumAddresses([...ethereumAddresses, ...params]);
                  setActiveUserEthereumAddress(params[0]);
                  setSuccessMessage(_t("g.done"));
                } else {
                  setErrorMessage(resp.errorMessage);
                }
              });
            } else {
              throw new Error("Could not parse server...");
              setErrorMessage(_t("g.internal-error"));
            }
          });
        })
        .catch((e) => {
          console.error(e);
          setErrorMessage(_t("g.internal-error"));
        })
        .finally(() => setLoadingEthereumAddresses(false));
    } // if
  }, [
    hasEthereumWallet,
    ethereumAddresses,
    sessionId,
    setActiveUserEthereumAddress,
    setErrorMessage,
    setSuccessMessage,
  ]);

  useEffect(() => {
    setConstructorCount((c) => c + 1);
    const profileConstructor = (jSONResponse: GetProfileResponse) => {
      if (jSONResponse.success) {
        const {
          givenNames,
          familyNames,
          flags,
          publicKeys,
          emailAddresses,
          bitcoincashAddress: bitcoinCashAddress,
        } = jSONResponse;
        console.error({ bitcoinCashAddress });
        onlySetPassword("");
        onlySetPassword2("");
        setGivenNames(givenNames.join(" "));
        setFamilyNames(familyNames.join(" "));
        setEthereumAddresses(publicKeys);
        setFlags(flags);
        setEmailAddresses(emailAddresses);
        setProfileInformationLoaded(true);
        setBitcoincashAddress(bitcoinCashAddress);
        setSavedBitcoincashAddress(bitcoinCashAddress);
      } else {
        const { errorMessage } = jSONResponse as GetProfileFailedResponse;
        //if (errorMessage ===  _t("backend.expired-or-not-logged-in")) {
        //  setSessionInfo(null, null, new Date(0));
        //  setView('login');
        //} else {
        setErrorMessage(errorMessage);
        //}
      }
    };

    if (!loadingProfileInformation && !profileInformationLoaded) {
      onlySetPassword("");
      onlySetPassword2("");
      setPasswordError("");
      setPassword2Error("");
      setEmailAddress("");
      setLoadingProfileInformation(true);
      try {
        console.log(sessionId, userNumber);

        Promise.any([
          fetch("/private-api/getProfile", {
            headers: {
              targetUser: "" + userNumber,
              session_id: sessionId,
            },
          }),
          sleep(fetchTimeout),
        ])
          .then(async (response) => {
            if (!response) {
              setErrorMessage(_t("g.timeout"));
              setProfileLoadFailureCount(profileLoadFailureCount + 1);
              return;
            }
            //await sleep(Math.floor(Math.random() * 1000));
            profileConstructor(await response.json());
          })
          .catch((e) => {
            // it might be internet connectivity, but blame the server instead.
            // it is many 9s before this would happen anway.
            if (e.message === _t("backend.expired-or-not-logged-in")) {
              setSessionInfo(null, null, new Date(0));
              setView("login");
            }
            setErrorMessage(_t("g.server-error"));
            console.log(e);
            setTimeout(
              () => setProfileLoadFailureCount(profileLoadFailureCount + 1),
              10000,
            );
          })
          .finally(() => {
            setLoadingProfileInformation(false);
          });
      } catch (e) {
        setErrorMessage(_t("g.server-error"));
        setLoadingProfileInformation(false);
        setTimeout(
          () => setProfileLoadFailureCount(profileLoadFailureCount + 1),
          10000,
        );
      }
    }
    return () => {
      setDestructorCount((d) => d + 1);
      setProfileInformationLoaded(false);
    };
    // don't pay attention to the demands from es-lint to add routines
    // to the dependencies.  Their values don't matter mathematically,
    // but cause continuous calls if they are included.
  }, [sessionId, userNumber, profileLoadFailureCount]);

  const setNewPassword = useCallback(
    (ev) => {
      Promise.any([
        fetch("/private-api/setPassword", {
          headers: {
            targetUser: "" + userNumber,
            session_id: sessionId,
            password: password,
          },
        }),
        sleep(fetchTimeout),
      ])
        .then((resp) => {
          if (!resp) {
            setErrorMessage(_t("g.timeout"));
            return;
          }
          if (resp.ok) {
            resp.json().then((jSON) => {
              console.log(jSON);
              const { success, errorMessage } = jSON;
              if (success) {
                // indicate this worked somehow...
                setSuccessMessage(_t("profile.password-set"));
              } else {
                setErrorMessage(errorMessage);
              }
            });
          } else {
            const { statusText } = resp;
            setErrorMessage(statusText);
          }
        })
        .catch((excep) => {
          console.error(excep);
          setErrorMessage(excep.message);
        });
    },
    [userNumber, sessionId, password, setErrorMessage, setSuccessMessage],
  );

  const setNewEmailAddress = useCallback(
    (userNumber: number, sessionId: string, emailAddress: string) => {
      console.log({ userNumber, sessionId, emailAddress });
      Promise.any([
        fetch("/private-api/setEmailAddress", {
          headers: {
            targetUser: "" + userNumber,
            session_id: sessionId,
            emailAddress,
          },
        }),
        sleep(fetchTimeout),
      ]).then((resp) => {
        if (!resp) {
          setErrorMessage(_t("g.timeout"));
        } else if (resp.ok) {
          resp
            .json()
            .then((jSON) => {
              console.log(jSON);
              const { success, errorMessage } = jSON;
              if (success) {
                setSuccessMessage(_t("g.done"));
                console.log(emailAddresses);
                setEmailAddresses([
                  ...emailAddresses,
                  { id: emailAddress, validated: false },
                ]);
              } else {
                setErrorMessage(errorMessage);
              }
            })
            .catch((e) => {
              // @ts-ignore
              setErrorMessage(e.message);
            });
        }
      });
    },
    [emailAddresses, setErrorMessage, setSuccessMessage],
  );

  const validateEmailAddress = (ev) => {
    (async () => {
      try {
        const validationResponse = await Promise.any([
          fetch("/private-api/validateEmailAddress", {
            headers: {
              validation_code: validationCode,
              session_id: sessionId,
            },
          }),
          sleep(fetchTimeout),
        ]);
        if (!validationResponse) {
          setErrorMessage(_t("g.timeout"));
          return;
        } else if (!validationResponse.ok) {
          throw new Error(_t("g.couldnt-fetch"));
        }
        const jSON = await validationResponse.json();
        if (!jSON.success) {
          throw new Error(jSON.errorMessage);
        }
        const emailAddressesCopy = [...emailAddresses];
        const emailEntry = emailAddressesCopy.find(
          (addr) => addr.id === jSON.emailAddress,
        );
        if (emailEntry) {
          emailEntry.validated = true;
          setEmailAddresses(emailAddressesCopy);
          setSuccessMessage(_t("profile.validated"));
        } else {
          setErrorMessage(_t("profile.not-validated"));
        }
      } catch (e) {
        console.log(e);
        setErrorMessage(_t("profile.not-validated"));
      }
    })();
  };

  if (loadingProfileInformation) {
    return <span>{_t("g.loading")}</span>;
  }

  if (!profileInformationLoaded) {
    return (
      <div>
        {givenNamesClickCount > 5 && (
          <div className="profile-section">
            {" "}
            Profile constructed {constructorCount} times. Profile dstructed{" "}
            {destructorCount} times. Profile load failed{" "}
            {profileLoadFailureCount} times.
          </div>
        )}
        no information and not loading
      </div>
    );
  }

  return (
    <article id="profile-page">
      {givenNamesClickCount > 5 && (
        <div className="profile-section">
          {" "}
          Profile constructed {constructorCount} times. Profile dstructed{" "}
          {destructorCount} times. Profile load failed {profileLoadFailureCount}{" "}
          times. {hasEthereumWallet ? "Ethereum" : "No ethereum"} wallet
          detected.
        </div>
      )}
      <div className="profile-section">
        <div className="horizontallyArranged">

            <h2>
              {" "}
              <span onClick={() => setGivenNamesClickCount((c) => c + 1)}>
                {givenNames}
              </span>{" "}
              {familyNames}
            </h2>
          </div>

          <div className="form-line">
            <label>{_t("profile.user-number")}:</label> <div>{userNumber}</div>
          </div>

          <div className="form-line verticallyArranged">
            {flags.map((v) => (
              <b key={v} className="flag">
                {v}
              </b>
            ))}
        </div>
      </div>

      <div className="profile-section" id="profile-email-update">
        <h3>{_t("profile.email-address")}</h3>

        {emailAddresses.find((addr) => addr.validated === false) && (
          <div>
            <label htmlFor="validationCodeInput">
              {_t("profile.enter-validation-code")}
            </label>

            <div>
              <input
                id="validationCodeInput"
                type="text"
                defaultValue={validationCode}
                onChange={(ev) => setValidationCode(ev.target.value)}
              />
            </div>

            <div>
              <button onClick={validateEmailAddress}>
                {_t("profile.confirm code")}
              </button>
            </div>
          </div>
        )}

        <table>
          <thead>
            {emailAddresses.length > 0 && (
              <tr>
                <td colSpan={3}>{_t("profile.email-addresses")}</td>
              </tr>
            )}
          </thead>
          <tbody>
            {emailAddresses.map((addr: { id: string; validated: boolean }) => (
              <tr key={addr.id}>
                <td />
                <td>{addr.id}</td>
                <td>
                  {addr.validated ? (
                    <i>{_t("profile.validated")}</i>
                  ) : (
                    <i>{_t("profile.not-validated")}</i>
                  )}
                </td>{" "}
              </tr>
            ))}

            <tr>
              <td>
                <label>{_t("profile.set-new-email-address")}</label>
              </td>
              <td>
                <input
                  type="email"
                  defaultValue={emailAddress}
                  disabled={false}
                  onChange={(ev) => setEmailAddress(ev.target.value)}
                />
                <span className="error"> {emailAddressError} </span>{" "}
              </td>
              <td>
                <button
                  onClick={(ev) =>
                    setNewEmailAddress(userNumber, sessionId, emailAddress)
                  }
                  disabled={emailAddress === ""}
                >
                  {_t("profile.set-new-email-address")}
                </button>{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        className="profile-section verticallyArranged"
        id="profile-password-update"
      >
        <h3>{_t("profile.password")}</h3>
        <div className="form-line">
          <label>{_t("profile.enter-new-password")}</label>
          <input
            id="proflie-password-input-field"
            defaultValue={password}
            type="password"
            onChange={(ev) => setPassword(ev.target.value)}
          />
          
        </div>
        <span className="error">{passwordError}</span>
        <div className="form-line">
          <label>{_t("profile.confirm-password")}</label>
          <input
            id="proflie-password2-input-field"
            onChange={(ev) => setPassword2(ev.target.value)}
            defaultValue={password2}
            type="password"
          />
        </div>
        <span className="error">{password2Error}</span>

        <button onClick={setNewPassword}>{_t("profile.set-password")}</button>
      </div>

      {hasEthereumWallet && (
        <div className="profile-section" id="profile-ethereum-address-update">
          <h3>{_t("profile.ethereum-addresses")}</h3>
          <ul>
            {ethereumAddresses.map((addr) => (
              <li key={addr}>
                {addr === activeUserEthereumAddress ? (
                  <div className="fa fa-check" />
                ) : (
                  <div />
                )}{" "}
                {addr}
              </li>
            ))}
          </ul>
          {loadingEthereumAddresses ? (
            "..."
          ) : (
            <button onClick={AddEthereumAddresses}>
              {_t("profile.add-address-to-profile")}
            </button>
          )}
        </div>
      )}

      <div id="bitcoin-cash-section" className="profile-section">
        <h3>{_t("profile.bitcoincash-address")}</h3>
        <input
          className="bitcoincash-address"
          ref={bitcoinEntryInputRef}
          type="text"
          defaultValue={bitcoinCashAddress || ""}
          onChange={handleBitcoincashAddressChanged}
        />
        <button
          onClick={saveBitcoincashAddress}
          disabled={activeUserBitcoinCashAddress === bitcoinCashAddress}
        >
          {_t("profile.update-bitcoincash-address")}
        </button>
        <span className="error">{bitcoinCashAddressError}</span>
      </div>
    </article>
  );
}
