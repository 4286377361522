import React from "react";

interface AddressSelectionProps {
  addresses: Array<string>;
  setAddress: (address: string) => void;
  address: string;
}

export function AddressSelectionList(props: AddressSelectionProps) {
  const { addresses, setAddress } = props;
  return (
    <div>
      {addresses?.map((address) => (
        <div className="form-line" key={address}>
          <input
            type="radio"
            name="public_key_selection"
            value={address}
            defaultChecked={address == props.address}
            onClick={(ev) => setAddress(address)}
          />{" "}
          <label>{address}</label>
        </div>
      ))}
    </div>
  );
}
